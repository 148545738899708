.overlay {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1;
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,.4);
}
a {
    text-decoration: none;
}
/* Header3 */
.header3-icon-text {
    cursor: pointer;
    color: #333;
    font-size: 1.3rem; 
    font-weight: 600;
    height: 30px;
}
/* Header2 banner */
.header2-warpper {
    width: 100%;
    position: relative;
}
.header3-warpper {
    width: 100%;
    margin-top: 70px;
    position: relative;
}
.header2-warpper .slick-prev, .header2-warpper .slick-next{
    z-index: -1;
}
.header3-warpper .slick-prev, .header2-warpper .slick-next{
    z-index: -1;
}
.header2-warpper .slick-dots {
    bottom: 20px;
    width: 90%;
    right: 0;
    margin-right: 1%;
    list-style: none;
    text-align: end;
    z-index: 1;
}
.header3-warpper .slick-dots {
    bottom: 20px;
    width: 90%;
    right: 0;
    margin-right: 1%;
    list-style: none;
    text-align: end;
    z-index: 1;
}
.header2-booking {
    position: absolute;
    display: flex;
    right: 1%;
    bottom: 60px;
    background-color: #333;
    padding: 5px;
    align-items: center;
    z-index: 1;
}
.header2-warpper .slick-dots li {
    margin: 0 5px;
}
.header3-warpper .slick-dots li {
    margin: 0 5px;
}
.header2-booking-img {
    width: 50px;
    height: 50px;
    background-size: cover;
    background-position: center;
}
.header2-right-nav {
    position: absolute;
    right: 2%;
    top: 50%;
    transform: translate(0, -50%);
    z-index: 1;
    text-align: center;
}
.header2-booking-text {
    color: #fff;
    font-size: 1.1rem;
    margin: 0 10px;
}
.header2-icon {
    display: block;
    width: 30px;
    height: 30px;
    margin-bottom: 30px;
}
.header2-nav-logo {
    display: block;
    position: absolute; 
    width: 120px;
    left: 2%;
    top: 20px;
    z-index: 1;
}
/* Header banner */
.header-warpper {
    margin-top: 68px;
    width: 100%;
    position: relative;
}
.header-video-wrap {
    position: relative;
    width: 100%;
    /* height: 800px; */
    /* overflow: hidden; */
}
.video-size {
    position: absolute;
    /* width: 1920px; */
    /* height: 1170px; */
    top: 50%;
    left: 50%;
    margin: -400px 0 0 -1040px;
}

.header-container {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: #fff;
    z-index: 1;
    padding: 0 10%;
}

/* 輪播 */
.slick-dots {
    bottom: 20px;
    width: 100%;
    margin: 0;
    list-style: none;
    text-align: center;
    z-index: 1;
}

.slick-dots li {
    margin: 0 10px;
}

.slick-prev {
    left: 15px;
    z-index: 1;
}

.slick-next {
    right: 15px;
    z-index: 1;
}

.slick-dots li button {
    width: 15px;
    height: 15px;
    color: white;
    border: 1px solid #fff;
    background-color: #ffffff47;
    border-radius: 20px;
}

.slick-dots li.slick-active button {
    opacity: .75;
    background-color: #fff;
}


.slick-dots li button:before {
    content: '';
}

@media screen and (max-width: 1400px) { 
    .header-warpper {
        /* height: 550px; */
    }
    .header-video-wrap {
        /* height: 38vw;
        overflow: hidden; */
    }
    .video-size {
        margin: -20vw 0 0 -50%;
    }
}
@media screen and (max-width: 768px) {
    .header2-nav-logo {
        width: 100px;
        /* top: 40px; */
    }
    .header-warpper {
        /* height: 540px; */
    }
    .slick-prev {
        z-index: -1;
    }
    
    .slick-next {
        z-index: -1;
    }
}

@media screen and (max-width: 414px) {
    .header2-nav-logo {
        width: 80px;
        top: 15px;
    }
    .header2-booking-text {
        color: #fff;
        font-size: .9rem;
        margin: 0 10px;
    }
    .header2-booking-img {
        width: 25px;
        height: 25px;
    }
    .header-warpper {
        margin-top: 44px;
    }
    .header3-warpper {
        margin-top: 50px;
    }
    .header2-icon {
        width: 15px;
        height: 15px;
        margin-bottom: 10px;
    }
    .header3-icon {
        width: 15px;
        height: 15px;
        margin-bottom: 10px;
    }
    .header3-icon-text {
        font-size: .9rem; 
        font-weight: 600;
        height: 30px;
    }
    .header2-booking {
        bottom: 10px;
    }
}
