@font-face {
  font-family: 'Trade Gothic';
  src:  url('../src/static/font/Trade Gothic LT Bold.woff') format("woff"),
        url('../src/static/font/Trade Gothic LT Bold.ttf') format("truetype"),
        url('../src/static/font/Trade Gothic LT Bold.eot') format("embedded-opentype");
}

body {
  margin: 0;
  font-family:'Open Sans', "Microsoft JhengHei","微軟正黑體", "Segoe UI", "Roboto",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",'Trade Gothic', -apple-system, BlinkMacSystemFont,
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
