@charset "UTF-8";
.icon-arrow-red {
  display: block;
  width: 100%;
  height: 100%;
  background-image: url("../../static/arrow-red.svg");
  background-size: cover;
  background-position: center; }

.icon-arrow-block {
  display: block;
  width: 100%;
  height: 100%;
  background-image: url("../../static/icon05.svg");
  background-size: cover;
  background-position: center; }

.icon-arrow-white {
  display: block;
  width: 100%;
  height: 100%;
  background-image: url("../../static/arrow-white.svg");
  background-size: cover;
  background-position: center; }

.icon-wine-red {
  display: block;
  width: 100%;
  height: 100%;
  background-image: url("../../static/wine-red.svg");
  background-size: cover;
  background-position: center; }

.icon-steak-red {
  display: block;
  width: 100%;
  height: 100%;
  background-image: url("../../static/icon01.svg");
  background-size: cover;
  background-position: center; }

.icon-email-gray {
  display: block;
  width: 100%;
  height: 100%;
  background-image: url("../../static/iconfinder.png");
  background-size: cover;
  background-position: center; }

.icon-map-marker-gray {
  display: block;
  width: 100%;
  height: 100%;
  background-image: url("../../static/map-marker-alt-solid.png");
  background-size: cover;
  background-position: center; }

.icon-search-red {
  display: block;
  width: 100%;
  height: 100%;
  background-image: url("../../static/search.svg");
  background-size: cover;
  background-position: center; }

.icon-phone-gray {
  display: block;
  width: 100%;
  height: 100%;
  background-image: url("../../static/phone-solid.png");
  background-size: cover;
  background-position: center; }

.icon-booking-white {
  display: block;
  width: 100%;
  height: 100%;
  background-image: url("../../static/phone-solid.png");
  background-size: cover;
  background-position: center; }

.icon-dateIcon-white {
  display: block;
  width: 100%;
  height: 100%;
  background-image: url("../../static/dateIcon.svg");
  background-size: cover;
  background-position: center; }

.header-top-wrap {
  background-color: #000;
  display: flex;
  position: fixed;
  z-index: 2;
  width: 100%;
  height: 32px;
  top: 0;
  left: 0;
  padding: 0 2%;
  align-items: center;
  justify-content: flex-end; }
  @media (max-width: 1310px) {
    .header-top-wrap {
      padding: 0 3%; } }
  @media (max-width: 1023px) {
    .header-top-wrap {
      top: 53px;
      transition: top 0.2s ease-in .3s;
      justify-content: center; } }
  @media (max-width: 414px) {
    .header-top-wrap {
      top: 18px; } }

@media (max-width: 1023px) {
  .header-top-wrap.is-active {
    top: 89px;
    transition: top 0.3s ease-in; } }

@media (max-width: 414px) {
  .header-top-wrap.is-active {
    top: 53px; } }

.header-top-arrow-icon {
  width: 18px;
  height: 14px; }

.header-top-text {
  color: #fff;
  font-size: .8rem;
  font-weight: 600;
  margin-right: 15px; }

.nav-list-wrap {
  width: 100%;
  background-color: #333;
  display: flex;
  position: fixed;
  z-index: 2;
  top: 0;
  left: 0;
  color: #fff;
  align-items: center;
  justify-content: space-between;
  padding: 10px 0; }
  @media (max-width: 1023px) {
    .nav-list-wrap {
      top: 0px;
      padding: 0 3%; } }
  @media (max-width: 768px) {
    .nav-list-wrap {
      padding: 5px 3% 0; } }
  @media (max-width: 414px) {
    .nav-list-wrap {
      padding: 5px 3% 1px; } }

.nav-list-bar {
  display: flex;
  width: 1160px;
  margin: 0 auto; }
  @media (max-width: 1366px) {
    .nav-list-bar {
      width: 90%; } }
  @media (max-width: 1023px) {
    .nav-list-bar {
      width: fit-content;
      margin: 0; } }

.nav-logo {
  width: 100px;
  height: 50px; }
  @media (max-width: 414px) {
    .nav-logo {
      width: 70px;
      height: 35px; } }

.nav {
  font-family: 'Trade Gothic',"Microsoft JhengHei","微軟正黑體";
  display: flex;
  width: 90%;
  justify-content: flex-end;
  align-items: center; }
  @media (max-width: 1023px) {
    .nav {
      position: fixed;
      overflow: hidden;
      justify-content: flex-start;
      width: 100%;
      height: 0;
      top: 89px;
      left: 0;
      z-index: 99;
      flex-flow: column;
      background-color: #333;
      transition: height 0.3s ease-in; } }
  @media (max-width: 1023px) {
    .nav {
      top: 68px; } }
  @media (max-width: 414px) {
    .nav {
      top: 44px;
      transition: height 0.6s ease-in; } }

@media (max-width: 1023px) {
  .nav.is-active {
    top: 89px;
    height: 100%;
    transition: height 0.1s ease-in 0.1s; } }

@media (max-width: 1023px) {
  .nav.is-active {
    top: 68px; } }

@media (max-width: 414px) {
  .nav.is-active {
    top: 44px; } }

.nav-button {
  display: block;
  background-color: transparent;
  color: #fff;
  border: none;
  font-size: 1rem;
  font-weight: bold;
  text-decoration: none;
  margin: 0 25px; }
  @media (max-width: 1366px) {
    .nav-button {
      font-size: 1rem; } }
  @media (max-width: 1023px) {
    .nav-button {
      margin: 1rem 0;
      font-size: 2rem; } }
  @media (max-width: 768px) {
    .nav-button {
      width: 80%;
      text-align: center;
      margin: 1.5rem auto;
      font-size: 1.5rem; } }
  @media (max-width: 414px) {
    .nav-button {
      margin: .8rem auto;
      font-size: 1.2rem; } }

.en-nav-button {
  display: block;
  background-color: transparent;
  color: #fff;
  border: none;
  font-size: 18px;
  font-weight: bold;
  text-decoration: none;
  margin: 0 2.2%; }
  @media (max-width: 1125px) {
    .en-nav-button {
      margin: 0 2%;
      font-size: 1rem; } }
  @media (max-width: 1023px) {
    .en-nav-button {
      margin: 1rem 0;
      font-size: 2rem; } }
  @media (max-width: 768px) {
    .en-nav-button {
      width: 80%;
      text-align: center;
      margin: 1.5rem auto;
      font-size: 1.5rem; } }
  @media (max-width: 414px) {
    .en-nav-button {
      margin: .8rem auto;
      font-size: 1.2rem; } }

@media (max-width: 1023px) {
  .nav-footer-button {
    margin: 1rem 2%;
    font-size: 1.2rem; } }

@media (max-width: 768px) {
  .nav-footer-button {
    width: auto;
    margin: .8rem 15px; } }

@media (max-width: 414px) {
  .nav-footer-button {
    font-size: 1rem;
    width: 33%;
    margin: .8rem auto; } }

@media (max-width: 375px) {
  .nav-footer-button {
    font-size: .8rem; } }

.nav-control {
  display: flex;
  align-items: center; }
  @media (max-width: 1023px) {
    .nav-control {
      order: -1;
      margin: 1rem 0; } }
  @media (max-width: 768px) {
    .nav-control {
      padding: 2rem 0 1rem;
      margin: 0; } }
  .nav-control .nav-change-lang {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 1%; }
    @media (max-width: 1023px) {
      .nav-control .nav-change-lang {
        font-size: 1.5rem; } }
    @media (max-width: 768px) {
      .nav-control .nav-change-lang {
        font-size: 1.2rem; } }
  .nav-control .nav-change-lang-bt {
    display: block;
    border: none;
    text-decoration: none;
    color: #fff;
    word-break: keep-all; }
    .nav-control .nav-change-lang-bt > span {
      cursor: pointer; }
  .nav-control .nav-change-lang-bt:after {
    content: '/';
    margin: 0 4px; }
  .nav-control .nav-change-lang-bt:last-child:after {
    display: none; }
  .nav-control .nav-slash {
    margin: 0 4px; }
  .nav-control .nav-member-icon {
    display: block;
    width: 26px;
    height: 26px;
    margin: 0 0 0 10px; }
    @media (max-width: 1023px) {
      .nav-control .nav-member-icon {
        width: 35px;
        height: 35px;
        margin: 0 0 0 15px; } }
    @media (max-width: 1023px) {
      .nav-control .nav-member-icon > img {
        width: 100%; } }

.nav-reservations {
  font-family: 'Trade Gothic',"Microsoft JhengHei","微軟正黑體";
  background-color: transparent;
  color: #fff;
  font-size: 1rem;
  font-weight: bold;
  border-width: 14px;
  transition: all .5s;
  border-image: url(../button-border-white.png) 18 18 stretch;
  padding: 0 10px;
  text-decoration: none;
  margin-left: 10px;
  cursor: pointer; }
  .nav-reservations a {
    color: #fff; }
  .nav-reservations:hover {
    border-image: url(../button-border-red.png) 18 18 stretch; }
  .nav-reservations:focus {
    outline: none; }
  @media (max-width: 1125px) {
    .nav-reservations {
      font-size: .9rem; } }
  @media (max-width: 1023px) {
    .nav-reservations {
      margin: 1rem 0;
      font-size: 1.5rem;
      border-image: url(../button-border-red.png) 18 18 stretch; } }
  @media (max-width: 414px) {
    .nav-reservations {
      font-size: 1.2rem; } }

.nav-mobile-reservations {
  font-family: 'Trade Gothic',"Microsoft JhengHei","微軟正黑體";
  display: none;
  color: #fff;
  font-size: 1.2rem;
  font-weight: 700;
  text-decoration: none; }
  .nav-mobile-reservations:focus {
    outline: none; }
  @media (max-width: 1023px) {
    .nav-mobile-reservations {
      display: block; } }
  @media (max-width: 414px) {
    .nav-mobile-reservations {
      font-size: 1rem; } }
  @media (max-width: 320px) {
    .nav-mobile-reservations {
      font-size: .8rem; } }

.nav-mobile-button {
  display: none;
  width: 100px;
  height: 45px;
  flex-direction: column;
  justify-content: space-between;
  padding: 3px;
  border: none;
  background-color: transparent;
  align-items: flex-end; }
  .nav-mobile-button .top, .nav-mobile-button .middle, .nav-mobile-button .bottom {
    background-color: #fff;
    width: 50px;
    height: 5px; }
    @media (max-width: 414px) {
      .nav-mobile-button .top, .nav-mobile-button .middle, .nav-mobile-button .bottom {
        width: 30px;
        height: 3px; } }
  .nav-mobile-button:focus {
    outline: none; }
  @media (max-width: 1023px) {
    .nav-mobile-button {
      display: flex; } }
  @media (max-width: 414px) {
    .nav-mobile-button {
      width: 70px;
      height: 28px; } }

.hamburger {
  display: none;
  padding: 10px;
  cursor: pointer;
  font: inherit;
  color: inherit;
  text-transform: none;
  background-color: transparent;
  border: 0;
  margin: 0;
  overflow: hidden; }
  @media (max-width: 1023px) {
    .hamburger {
      display: inline-block; } }
  .hamburger .hamburger-box {
    width: 50px;
    height: 45px;
    display: inline-block;
    position: relative; }
  .hamburger .hamburger-inner {
    display: block;
    top: 50%;
    margin-top: -2.5px;
    transition: width 0.8s cubic-bezier(0.075, 0.82, 0.165, 1); }
  .hamburger .hamburger-inner::before {
    top: -16px;
    left: 0;
    transition: transform 0.125s cubic-bezier(0.6, 0.04, 0.98, 0.335), top 0.05s 0.125s linear, left 0.125s 0.175s ease-in; }
  .hamburger .hamburger-inner::after {
    top: 16px;
    right: 0;
    transition: transform 0.125s cubic-bezier(0.6, 0.04, 0.98, 0.335), top 0.05s 0.125s linear, right 0.125s 0.175s ease-in; }
  .hamburger .hamburger-inner, .hamburger .hamburger-inner::before, .hamburger .hamburger-inner::after {
    width: 50px;
    height: 5px;
    background-color: #FFF;
    position: absolute; }
  .hamburger .hamburger-inner::before, .hamburger .hamburger-inner::after {
    content: "";
    display: block; }
  @media (max-width: 414px) {
    .hamburger {
      padding: 5px; }
      .hamburger .hamburger-box {
        width: 30px;
        height: 25px; }
      .hamburger .hamburger-inner {
        display: block;
        top: 50%;
        margin-top: 0px; }
      .hamburger .hamburger-inner::before {
        top: -8px;
        left: 0; }
      .hamburger .hamburger-inner::after {
        top: 8px;
        right: 0; }
      .hamburger .hamburger-inner, .hamburger .hamburger-inner::before, .hamburger .hamburger-inner::after {
        width: 30px;
        height: 2px; } }

.hamburger.is-active .hamburger-inner {
  width: 0px;
  transition-delay: 0s;
  transition-timing-function: ease-out;
  background-color: transparent !important;
  transition: width 0.25s cubic-bezier(0.075, 0.82, 0.165, 1); }

.hamburger.is-active .hamburger-inner::before {
  left: -80px;
  top: -80px;
  transform: translate3d(80px, 80px, 0) rotate(45deg);
  transition: left 0.2s ease-out, top 0.05s 0.2s linear, transform 0.2s 0.175s cubic-bezier(0.075, 0.82, 0.165, 1); }

.hamburger.is-active .hamburger-inner::after {
  right: -130px;
  top: -80px;
  transform: translate3d(-80px, 80px, 0) rotate(-45deg);
  transition: right 0.25s ease-out, top 0.05s 0.2s linear, transform 0.2s 0.175s cubic-bezier(0.075, 0.82, 0.165, 1); }
  @media (max-width: 414px) {
    .hamburger.is-active .hamburger-inner::after {
      right: -110px; } }

.header-icon {
  width: 40px;
  height: 40px; }
  @media (max-width: 1280px) {
    .header-icon {
      width: 38px;
      height: 38px; } }
  @media (max-width: 414px) {
    .header-icon {
      width: 23px;
      height: 23px; } }

.header-title {
  font-family: 'Trade Gothic',"Microsoft JhengHei","微軟正黑體";
  text-transform: uppercase;
  width: 100%;
  font-size: 4rem;
  color: #fff;
  text-align: center;
  font-weight: 800;
  margin: 15px auto 25px;
  letter-spacing: -2px;
  line-height: 4rem; }
  @media (max-width: 1280px) {
    .header-title {
      text-transform: uppercase;
      width: 100%;
      font-size: 3.5rem;
      color: #fff;
      text-align: center;
      line-height: 3rem; } }
  @media (max-width: 768px) {
    .header-title {
      margin: 15px auto 10px;
      text-transform: uppercase;
      width: 100%;
      font-size: 2.5rem;
      color: #fff;
      text-align: center;
      line-height: 2.5rem; } }
  @media (max-width: 414px) {
    .header-title {
      text-transform: uppercase;
      width: 100%;
      font-size: 1.7rem;
      color: #fff;
      text-align: center;
      line-height: 1.7rem;
      margin: 5px auto;
      letter-spacing: 0px; } }

.header-inner-text {
  text-transform: uppercase;
  width: 100%;
  font-size: 1.3rem;
  color: #fff;
  text-align: center;
  text-transform: none;
  font-weight: 400;
  line-height: 1.8rem; }
  @media (max-width: 768px) {
    .header-inner-text {
      text-transform: uppercase;
      width: 100%;
      font-size: 1.3rem;
      color: #fff;
      text-align: center;
      text-transform: none;
      line-height: 1.5rem; } }
  @media (max-width: 414px) {
    .header-inner-text {
      text-transform: uppercase;
      width: 100%;
      font-size: 0.7rem;
      color: #fff;
      text-align: center;
      text-transform: none;
      line-height: .9rem; } }

.content-warpper {
  width: 100%; }

.content-sub-warpper {
  width: 1160px;
  margin: 80px auto 200px; }
  @media (max-width: 1366px) {
    .content-sub-warpper {
      width: 90%; } }
  @media (max-width: 768px) {
    .content-sub-warpper {
      margin: 50px auto 100px; } }
  @media (max-width: 414px) {
    .content-sub-warpper {
      margin: 40px auto 100px; } }

.content-sub-innner-warp {
  margin: 35px 0; }
  @media (max-width: 1400px) {
    .content-sub-innner-warp {
      margin: 15px 0; } }
  @media (max-width: 414px) {
    .content-sub-innner-warp {
      margin: 10px 0; } }

.home-left-innerWarp {
  border-bottom: 1px solid #d2d2d2;
  padding-bottom: 35px; }
  @media (max-width: 1400px) {
    .home-left-innerWarp {
      padding-bottom: 15px; } }

.home-rightup-innerWarp {
  border-bottom: 1px solid #d2d2d2;
  padding-bottom: 35px;
  margin-bottom: 35px; }
  @media (max-width: 1400px) {
    .home-rightup-innerWarp {
      margin-bottom: 15px;
      padding-bottom: 15px; } }

.content-inner-title-warpper {
  width: 1160px;
  margin: 88px auto 50px; }
  @media (max-width: 1400px) {
    .content-inner-title-warpper {
      width: 90%;
      margin: 70px auto 35px; } }
  @media (max-width: 768px) {
    .content-inner-title-warpper {
      margin: 50px auto 35px; } }
  @media (max-width: 414px) {
    .content-inner-title-warpper {
      width: 90%;
      margin: 35px auto 20px; } }

.about-us-title-warpper {
  width: 1160px;
  margin: 88px auto 50px; }
  @media (max-width: 1400px) {
    .about-us-title-warpper {
      width: 90%;
      margin: 70px auto 35px; } }
  @media (max-width: 768px) {
    .about-us-title-warpper {
      margin: 50px auto 35px; } }
  @media (max-width: 414px) {
    .about-us-title-warpper {
      width: 100%;
      margin: 35px auto 20px; } }

.food-drink-title-warpper {
  width: 100%;
  margin: 80px auto 50px; }
  @media (max-width: 1280px) {
    .food-drink-title-warpper {
      margin: 70px auto 35px; } }
  @media (max-width: 768px) {
    .food-drink-title-warpper {
      margin: 50px auto 35px; } }
  @media (max-width: 414px) {
    .food-drink-title-warpper {
      width: 100%;
      margin: 35px auto 20px; } }

.private-events-title-warpper {
  width: 1160px;
  margin: 80px auto 50px; }
  @media (max-width: 1400px) {
    .private-events-title-warpper {
      width: 90%;
      margin: 70px auto 35px; } }
  @media (max-width: 768px) {
    .private-events-title-warpper {
      margin: 50px auto 35px; } }
  @media (max-width: 414px) {
    .private-events-title-warpper {
      margin: 35px auto 20px; } }

.promotions-title-warpper {
  width: 1160px;
  margin: 80px auto 50px; }
  @media (max-width: 1400px) {
    .promotions-title-warpper {
      width: 90%;
      margin: 70px auto 35px; } }
  @media (max-width: 768px) {
    .promotions-title-warpper {
      margin: 50px auto 35px; } }
  @media (max-width: 414px) {
    .promotions-title-warpper {
      margin: 35px auto 20px; } }

.content-food-title-warpper {
  width: 1160px;
  margin: 80px auto 50px; }
  @media (max-width: 1366px) {
    .content-food-title-warpper {
      width: 90%;
      margin: 88px auto 35px; } }
  @media (max-width: 768px) {
    .content-food-title-warpper {
      margin: 75px auto 35px; } }
  @media (max-width: 414px) {
    .content-food-title-warpper {
      margin: 45px auto 20px; } }

.content-inner-warpper {
  width: 1160px;
  margin: 70px auto; }
  @media (max-width: 1366px) {
    .content-inner-warpper {
      width: 90%;
      margin: 35px auto; } }
  @media (max-width: 768px) {
    .content-inner-warpper {
      margin: 25px auto; } }

.content-inner-warpper-bottom {
  width: 1160px;
  margin: 70px auto 150px; }
  @media (max-width: 1366px) {
    .content-inner-warpper-bottom {
      width: 90%;
      margin: 35px auto 150px; } }
  @media (max-width: 768px) {
    .content-inner-warpper-bottom {
      margin: 25px auto 100px; } }

.content-inner-warpper-bottom.top-side {
  width: 1160px;
  margin: 70px auto; }
  @media (max-width: 1366px) {
    .content-inner-warpper-bottom.top-side {
      width: 90%;
      margin: 35px auto; } }
  @media (max-width: 768px) {
    .content-inner-warpper-bottom.top-side {
      margin: 25px auto; } }

.content-inner-bottom-warpper {
  width: 1160px;
  margin: 50px auto 200px; }
  @media (max-width: 1366px) {
    .content-inner-bottom-warpper {
      width: 90%;
      margin: 35px auto 150px; } }
  @media (max-width: 768px) {
    .content-inner-bottom-warpper {
      margin: 25px auto 150px; } }

.content-title {
  font-family: 'Trade Gothic',"Microsoft JhengHei","微軟正黑體";
  width: 100%;
  font-weight: 800;
  margin: 0 auto 35px;
  text-transform: uppercase;
  width: 100%;
  font-size: 4rem;
  color: #333;
  text-align: center;
  letter-spacing: -2px; }
  @media (max-width: 1366px) {
    .content-title {
      font-size: 3.5rem;
      margin: 0 auto 25px; } }
  @media (max-width: 1023px) {
    .content-title {
      font-size: 2.8rem; } }
  @media (max-width: 768px) {
    .content-title {
      margin: 0 auto 10px;
      font-size: 2.5rem; } }
  @media (max-width: 414px) {
    .content-title {
      font-size: 1.5rem;
      margin: 0 auto 5px;
      letter-spacing: 0px; } }

.content-about-us-title {
  font-family: 'Trade Gothic',"Microsoft JhengHei","微軟正黑體";
  letter-spacing: -1px;
  width: 100%;
  font-weight: 800;
  margin: 0 auto 35px;
  text-transform: uppercase;
  width: 100%;
  font-size: 3.5rem;
  color: #333;
  text-align: center; }
  @media (max-width: 1366px) {
    .content-about-us-title {
      font-size: 3rem;
      margin: 0 auto 25px; } }
  @media (max-width: 1023px) {
    .content-about-us-title {
      font-size: 2.8rem; } }
  @media (max-width: 768px) {
    .content-about-us-title {
      margin: 0 auto 10px;
      font-size: 2.5rem; } }
  @media (max-width: 414px) {
    .content-about-us-title {
      font-size: 1.5rem;
      margin: 0 auto 5px;
      letter-spacing: 0px;
      padding: 0 10px; } }

.food-subTitle {
  width: 100%;
  font-weight: 700;
  margin: 0 auto 20px;
  text-transform: uppercase;
  width: 100%;
  font-size: 2.5rem;
  color: #333;
  text-align: center;
  letter-spacing: -1px; }
  @media (max-width: 1023px) {
    .food-subTitle {
      font-size: 2rem; } }
  @media (max-width: 768px) {
    .food-subTitle {
      font-size: 1.5rem; } }
  @media (max-width: 414px) {
    .food-subTitle {
      font-size: 1.2rem; } }

.content-sub-title {
  font-family: 'Trade Gothic',"Microsoft JhengHei","微軟正黑體";
  letter-spacing: -1px;
  font-size: 2.2rem;
  color: #5a5a5a;
  text-transform: uppercase;
  line-height: 1;
  font-weight: 700;
  transform: scale(1, 1.1); }
  @media (max-width: 768px) {
    .content-sub-title {
      font-size: 2rem; } }
  @media (max-width: 414px) {
    .content-sub-title {
      font-size: 1.2rem; } }

.content-subTitle-red {
  font-family: 'Trade Gothic',"Microsoft JhengHei","微軟正黑體";
  font-size: 2.2rem;
  text-transform: uppercase;
  line-height: 1;
  font-weight: 700;
  -webkit-font-smoothing: antialiased;
  color: #be2f37;
  align-self: center;
  font-weight: 800;
  letter-spacing: -2px;
  height: 30%;
  display: flex;
  align-items: center; }
  @media (max-width: 768px) {
    .content-subTitle-red {
      font-size: 1.5rem; } }
  @media (max-width: 670px) {
    .content-subTitle-red {
      letter-spacing: -1px;
      font-size: 1.2rem; } }
  @media (max-width: 414px) {
    .content-subTitle-red {
      font-size: 1.2rem; } }
  @media (max-width: 360px) {
    .content-subTitle-red {
      font-size: 1rem; } }

.content-subTitle-red-center {
  font-family: 'Trade Gothic',"Microsoft JhengHei","微軟正黑體";
  font-size: 2.2rem;
  text-transform: uppercase;
  line-height: 1;
  font-weight: 700;
  -webkit-font-smoothing: antialiased;
  color: #be2f37;
  align-self: center;
  font-weight: 800;
  letter-spacing: -2px;
  height: 35%;
  display: flex;
  align-items: center;
  border: none;
  border-top: 1px solid #d2d2d2;
  border-bottom: 1px solid #d2d2d2; }
  @media (max-width: 1023px) {
    .content-subTitle-red-center {
      border: none; } }
  @media (max-width: 768px) {
    .content-subTitle-red-center {
      font-size: 1.5rem; } }
  @media (max-width: 670px) {
    .content-subTitle-red-center {
      letter-spacing: -1px;
      font-size: 1.2rem; } }
  @media (max-width: 414px) {
    .content-subTitle-red-center {
      font-size: 1.2rem; } }
  @media (max-width: 360px) {
    .content-subTitle-red-center {
      font-size: 1rem; } }

.content-subTitle-gray {
  font-size: 2.2rem;
  text-transform: uppercase;
  line-height: 1;
  font-weight: 700;
  -webkit-font-smoothing: antialiased;
  font-family: 'Trade Gothic',"Microsoft JhengHei","微軟正黑體";
  letter-spacing: -1px;
  font-size: 1.8rem;
  line-height: 1.8rem;
  color: #5a5a5a;
  align-self: center; }
  @media (max-width: 768px) {
    .content-subTitle-gray {
      font-size: 1.5rem;
      line-height: 1.5rem; } }
  @media (max-width: 414px) {
    .content-subTitle-gray {
      font-size: 1.2rem;
      line-height: 1.2rem; } }

.content-sub-title-inner {
  font-size: 1.3rem;
  line-height: 1.9rem;
  letter-spacing: 1px;
  color: #5a5a5a;
  text-align: center; }
  @media (max-width: 1366px) {
    .content-sub-title-inner {
      line-height: 1.8rem; } }
  @media (max-width: 1024px) {
    .content-sub-title-inner {
      font-size: 1.2rem; } }
  @media (max-width: 414px) {
    .content-sub-title-inner {
      letter-spacing: 0.3px;
      font-size: .9rem;
      line-height: 1.2rem; } }

.en-content-sub-title-inner {
  width: 100%;
  font-size: 1.3rem;
  line-height: 1.8rem;
  color: #5a5a5a;
  text-align: center; }
  @media (max-width: 768px) {
    .en-content-sub-title-inner {
      font-size: 1.3rem;
      line-height: 1.5rem; } }
  @media (max-width: 414px) {
    .en-content-sub-title-inner {
      font-size: 1rem;
      line-height: 1.2rem; } }

.giftcards-title-inner {
  font-size: 1.3rem;
  line-height: 1.9rem;
  color: #5a5a5a; }
  @media (max-width: 1366px) {
    .giftcards-title-inner {
      line-height: 1.8rem; } }
  @media (max-width: 1024px) {
    .giftcards-title-inner {
      font-size: 1.2rem; } }
  @media (max-width: 414px) {
    .giftcards-title-inner {
      font-size: .9rem;
      line-height: 1.2rem; } }

.en-giftcards-title-inner {
  width: 100%;
  font-size: 1.3rem;
  line-height: 1.6rem;
  color: #5a5a5a; }
  @media (max-width: 768px) {
    .en-giftcards-title-inner {
      font-size: 1.2rem;
      line-height: 1.5rem; } }
  @media (max-width: 414px) {
    .en-giftcards-title-inner {
      font-size: 1rem;
      line-height: 1.2rem; } }

.en-content-inner {
  font-size: 1.3rem;
  line-height: 1.6rem;
  color: #5a5a5a; }
  @media (max-width: 768px) {
    .en-content-inner {
      font-size: 1.2rem;
      line-height: 1.4rem; } }
  @media (max-width: 414px) {
    .en-content-inner {
      font-size: 1rem;
      line-height: 1.2rem; } }

.en-lunch-inner {
  font-size: 1.3rem;
  line-height: 1.6rem;
  color: #8f8f8f;
  font-style: italic;
  text-align: center; }
  @media (max-width: 768px) {
    .en-lunch-inner {
      font-size: 1.2rem;
      line-height: 1.4rem; } }
  @media (max-width: 414px) {
    .en-lunch-inner {
      font-size: 1rem;
      line-height: 1.2rem; } }

.footer-blockR-inner {
  font-size: 1.3rem;
  line-height: 1.8rem;
  color: #8f8f8f;
  text-align: justify;
  text-align-last: center; }
  @media (max-width: 1280px) {
    .footer-blockR-inner {
      font-size: 1.3rem; } }
  @media (max-width: 768px) {
    .footer-blockR-inner {
      font-size: 1.2rem;
      line-height: 1.3rem; } }
  @media (max-width: 414px) {
    .footer-blockR-inner {
      font-size: 1rem;
      line-height: 1.2rem; } }

.en-footer-blockR-inner {
  font-size: 1.3rem;
  line-height: 1.8rem;
  color: #8f8f8f; }
  @media (max-width: 1280px) {
    .en-footer-blockR-inner {
      font-size: 1.3rem;
      line-height: 1.5rem; } }
  @media (max-width: 768px) {
    .en-footer-blockR-inner {
      font-size: 1.3rem;
      line-height: 1.8rem; } }
  @media (max-width: 414px) {
    .en-footer-blockR-inner {
      font-size: 1rem;
      line-height: 1.2rem; } }

.en-about-us-content-title {
  font-size: 1.5rem;
  line-height: 1.7rem;
  color: #7f7f7d;
  padding: 0 100px;
  text-align: center; }
  @media (max-width: 1366px) {
    .en-about-us-content-title {
      padding: 0 10%;
      font-size: 1.4rem;
      line-height: 1.6rem; } }
  @media (max-width: 768px) {
    .en-about-us-content-title {
      font-size: 1.3rem;
      line-height: 1.5rem;
      padding: 0; } }
  @media (max-width: 414px) {
    .en-about-us-content-title {
      width: 90%;
      margin: 0 auto;
      font-size: 1rem;
      line-height: 1.3rem; } }

.about-us-content-title {
  font-size: 1.3rem;
  line-height: 1.9rem;
  letter-spacing: 1px;
  color: #5a5a5a;
  padding: 0 100px;
  text-align: justify;
  text-align-last: center; }
  @media (max-width: 1366px) {
    .about-us-content-title {
      padding: 0 10%;
      font-size: 1.2rem;
      line-height: 1.6rem;
      letter-spacing: .5px; } }
  @media (max-width: 1024px) {
    .about-us-content-title {
      font-size: 1.2rem; } }
  @media (max-width: 768px) {
    .about-us-content-title {
      padding: 0;
      font-size: 1rem;
      line-height: 1.4rem; } }
  @media (max-width: 414px) {
    .about-us-content-title {
      width: 90%;
      margin: 0 auto;
      font-size: .9rem;
      line-height: 1.35rem; } }

.en-about-us-content-inner {
  font-size: 1.5rem;
  line-height: 1.7rem;
  color: #7f7f7d; }
  @media (max-width: 1366px) {
    .en-about-us-content-inner {
      font-size: 1.4rem;
      line-height: 1.6rem; } }
  @media (max-width: 768px) {
    .en-about-us-content-inner {
      font-size: 1.3rem;
      line-height: 1.5rem; } }
  @media (max-width: 414px) {
    .en-about-us-content-inner {
      width: 90%;
      margin: 0 auto;
      font-size: 1rem;
      line-height: 1.3rem; } }

.about-us-content-inner {
  font-size: 1.3rem;
  line-height: 1.9rem;
  letter-spacing: 1px;
  color: #5a5a5a;
  text-align: justify; }
  @media (max-width: 1366px) {
    .about-us-content-inner {
      font-size: 1.2rem;
      line-height: 1.6rem;
      letter-spacing: .5px; } }
  @media (max-width: 1024px) {
    .about-us-content-inner {
      font-size: 1.2rem; } }
  @media (max-width: 768px) {
    .about-us-content-inner {
      font-size: 1rem;
      line-height: 1.4rem; } }
  @media (max-width: 414px) {
    .about-us-content-inner {
      width: 90%;
      margin: 0 auto;
      font-size: .9rem;
      line-height: 1.35rem; } }

.content-inner {
  font-size: 1.3rem;
  line-height: 1.5rem;
  color: #8f8f8f; }
  @media (max-width: 1280px) {
    .content-inner {
      font-size: 1.15rem;
      line-height: 1.45rem; } }
  @media (max-width: 414px) {
    .content-inner {
      font-size: .9rem;
      line-height: 1.1rem; } }

.content-giftcards-inner {
  font-size: 1.5rem;
  line-height: 1.8rem;
  color: #8f8f8f;
  text-align: center; }
  @media (max-width: 1280px) {
    .content-giftcards-inner {
      font-size: 1.3rem;
      line-height: 1.5rem; } }
  @media (max-width: 768px) {
    .content-giftcards-inner {
      font-size: 1.2rem;
      line-height: 1.3rem; } }
  @media (max-width: 414px) {
    .content-giftcards-inner {
      font-size: 1rem;
      line-height: 1.2rem; } }

.content-inner-privacy {
  font-size: 1.5rem;
  line-height: 1.8rem;
  color: #8f8f8f; }
  @media (max-width: 1280px) {
    .content-inner-privacy {
      font-size: 1.3rem; } }
  @media (max-width: 768px) {
    .content-inner-privacy {
      font-size: 1.2rem;
      line-height: 1.3rem; } }
  @media (max-width: 414px) {
    .content-inner-privacy {
      font-size: 1rem;
      line-height: 1.2rem; } }

.content-inner-red {
  color: #be2f37;
  text-decoration: none;
  word-break: break-word; }

.content-prev-footer {
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 7% 20%;
  background-image: url(../bg-prefooter.jpg);
  background-size: cover;
  background-position: right; }
  @media (max-width: 1620px) {
    .content-prev-footer {
      padding: 7% 15%; } }
  @media (max-width: 1450px) {
    .content-prev-footer {
      padding: 7% 10%; } }
  @media (max-width: 1366px) {
    .content-prev-footer {
      padding: 7% 5%; } }
  @media (max-width: 768px) {
    .content-prev-footer {
      padding: 7% 5% 15%; } }
  @media (max-width: 768px) {
    .content-prev-footer {
      flex-direction: column;
      align-items: center;
      text-align: center; } }

.content-video-wrap {
  height: 45vw; }

.content-giftcards-prev-footer {
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 7% 20%;
  background-image: url(../bg-prefooter.jpg);
  background-size: cover;
  background-position: right;
  flex-direction: column;
  text-align: center; }
  @media (max-width: 1620px) {
    .content-giftcards-prev-footer {
      padding: 7% 15%; } }
  @media (max-width: 1450px) {
    .content-giftcards-prev-footer {
      padding: 7% 10%; } }
  @media (max-width: 1366px) {
    .content-giftcards-prev-footer {
      padding: 7% 5%; } }
  @media (max-width: 768px) {
    .content-giftcards-prev-footer {
      padding: 7% 5% 15%; } }
  @media (max-width: 414px) {
    .content-giftcards-prev-footer {
      padding: 10% 5% 16%; } }
  @media (max-width: 375px) {
    .content-giftcards-prev-footer {
      padding: 10% 5% 25%; } }

.content-reservation-prev-footer {
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 7% 20%;
  background-image: url(../bg-prefooter.jpg);
  background-size: cover;
  background-position: right;
  flex-direction: column;
  text-align: center;
  align-items: center; }
  @media (max-width: 1620px) {
    .content-reservation-prev-footer {
      padding: 7% 15%; } }
  @media (max-width: 1450px) {
    .content-reservation-prev-footer {
      padding: 7% 10%; } }
  @media (max-width: 1366px) {
    .content-reservation-prev-footer {
      padding: 7% 5%; } }
  @media (max-width: 768px) {
    .content-reservation-prev-footer {
      padding: 7% 5% 15%; } }
  .content-reservation-prev-footer a {
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    padding: 0 20px; }
  .content-reservation-prev-footer i {
    display: inline-block;
    width: 30px;
    height: 26px;
    margin-right: 15px; }
  .content-reservation-prev-footer span {
    display: inline-block; }
  @media (max-width: 768px) {
    .content-reservation-prev-footer {
      padding: 10% 5% 20%; } }
  @media (max-width: 414px) {
    .content-reservation-prev-footer {
      padding: 20% 5%; }
      .content-reservation-prev-footer a {
        margin-bottom: 40px; } }

.content-prev-footer-block {
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 50%;
  height: 100%;
  padding: 0 3%; }
  @media (max-width: 768px) {
    .content-prev-footer-block {
      width: 90%;
      padding: 20px; } }

.content-prev-footer-blockR {
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 50%;
  height: 100%;
  padding: 0 3% 0 8%; }
  @media (max-width: 768px) {
    .content-prev-footer-blockR {
      width: 100%;
      padding: 20px 20px 40px 20px; } }

.content-prev-footer-bt {
  width: fit-content;
  display: block;
  text-transform: uppercase;
  color: #be2f37;
  text-align: center;
  font-size: 1.1rem;
  font-weight: bold;
  background-color: transparent;
  margin: 2rem 0;
  border: 15px solid;
  border-image: url(../button-border-gray.png) 18 18 stretch;
  padding: .25rem 0;
  cursor: pointer;
  text-decoration: none;
  font-family: 'Trade Gothic',"Microsoft JhengHei","微軟正黑體";
  padding: 0 25px 0 25px; }
  .content-prev-footer-bt:hover {
    border-image: url(../button-border-deepGray.png) 18 18 stretch; }
  .content-prev-footer-bt:focus {
    outline: none; }
  @media (max-width: 768px) {
    .content-prev-footer-bt {
      width: fit-content;
      display: block;
      text-transform: uppercase;
      color: #be2f37;
      text-align: center;
      font-size: 1.1rem;
      font-weight: bold;
      background-color: transparent;
      margin: 2rem 0;
      border: 15px solid;
      border-image: url(../button-border-gray.png) 18 18 stretch;
      padding: .25rem 0;
      cursor: pointer;
      text-decoration: none;
      padding: 0 20px 0 20px;
      font-size: .9rem;
      border: 12px solid;
      border-image: url(../button-border-gray.png) 18 18 stretch; }
      .content-prev-footer-bt:hover {
        border-image: url(../button-border-deepGray.png) 18 18 stretch; }
      .content-prev-footer-bt:focus {
        outline: none; } }
  @media (max-width: 414px) {
    .content-prev-footer-bt {
      width: fit-content;
      display: block;
      text-transform: uppercase;
      color: #be2f37;
      text-align: center;
      font-size: 1.1rem;
      font-weight: bold;
      background-color: transparent;
      margin: 2rem 0;
      border: 15px solid;
      border-image: url(../button-border-gray.png) 18 18 stretch;
      padding: .25rem 0;
      cursor: pointer;
      text-decoration: none;
      padding: 0 20px 0 20px;
      font-size: .85rem;
      border: 12px solid;
      border-image: url(../button-border-gray.png) 18 18 stretch; }
      .content-prev-footer-bt:hover {
        border-image: url(../button-border-deepGray.png) 18 18 stretch; }
      .content-prev-footer-bt:focus {
        outline: none; } }

.content-gray-bt {
  width: fit-content;
  display: block;
  text-transform: uppercase;
  color: #be2f37;
  text-align: center;
  font-size: 1.1rem;
  font-weight: bold;
  background-color: transparent;
  margin: 2rem 0;
  border: 15px solid;
  border-image: url(../button-border-gray.png) 18 18 stretch;
  padding: .25rem 0;
  cursor: pointer;
  text-decoration: none;
  font-family: 'Trade Gothic',"Microsoft JhengHei","微軟正黑體";
  margin: 2rem auto;
  padding: 0 50px; }
  .content-gray-bt:hover {
    border-image: url(../button-border-deepGray.png) 18 18 stretch; }
  .content-gray-bt:focus {
    outline: none; }
  @media (max-width: 414px) {
    .content-gray-bt {
      width: fit-content;
      display: block;
      text-transform: uppercase;
      color: #be2f37;
      text-align: center;
      font-size: 1.1rem;
      font-weight: bold;
      background-color: transparent;
      margin: 2rem 0;
      border: 15px solid;
      border-image: url(../button-border-gray.png) 18 18 stretch;
      padding: .25rem 0;
      cursor: pointer;
      text-decoration: none;
      padding: 0 35px;
      font-size: .85rem;
      margin: 1rem auto;
      border: 12px solid;
      border-image: url(../button-border-gray.png) 18 18 stretch; }
      .content-gray-bt:hover {
        border-image: url(../button-border-deepGray.png) 18 18 stretch; }
      .content-gray-bt:focus {
        outline: none; } }

.content-food-bt {
  width: fit-content;
  display: block;
  text-transform: uppercase;
  color: #be2f37;
  text-align: center;
  font-size: 1.1rem;
  font-weight: bold;
  background-color: transparent;
  margin: 2rem 0;
  border: 15px solid;
  border-image: url(../button-border-gray.png) 18 18 stretch;
  padding: .25rem 0;
  cursor: pointer;
  text-decoration: none;
  font-family: 'Trade Gothic',"Microsoft JhengHei","微軟正黑體";
  margin: 2rem auto;
  padding: 0 1rem; }
  .content-food-bt:hover {
    border-image: url(../button-border-deepGray.png) 18 18 stretch; }
  .content-food-bt:focus {
    outline: none; }
  @media (max-width: 1023px) {
    .content-food-bt {
      margin: 2rem auto 0; } }
  @media (max-width: 414px) {
    .content-food-bt {
      width: fit-content;
      display: block;
      text-transform: uppercase;
      color: #be2f37;
      text-align: center;
      font-size: 1.1rem;
      font-weight: bold;
      background-color: transparent;
      margin: 2rem 0;
      border: 15px solid;
      border-image: url(../button-border-gray.png) 18 18 stretch;
      padding: .25rem 0;
      cursor: pointer;
      text-decoration: none;
      word-break: keep-all;
      margin: 2rem auto 0;
      padding: 0 12px;
      font-size: 13px;
      border: 12px solid;
      border-image: url(../button-border-gray.png) 18 18 stretch; }
      .content-food-bt:hover {
        border-image: url(../button-border-deepGray.png) 18 18 stretch; }
      .content-food-bt:focus {
        outline: none; } }

.content-home-bt {
  font-family: 'Trade Gothic',"Microsoft JhengHei","微軟正黑體";
  width: fit-content;
  display: block;
  text-transform: uppercase;
  color: #be2f37;
  text-align: center;
  font-size: 1.1rem;
  font-weight: bold;
  background-color: transparent;
  margin: 2rem 0;
  border: 15px solid;
  border-image: url(../button-border-gray.png) 18 18 stretch;
  padding: .25rem 0;
  cursor: pointer;
  text-decoration: none;
  margin: 2rem 0;
  padding: 0 1rem; }
  .content-home-bt:hover {
    border-image: url(../button-border-deepGray.png) 18 18 stretch; }
  .content-home-bt:focus {
    outline: none; }
  @media (max-width: 1023px) {
    .content-home-bt {
      margin: 0; } }
  @media (max-width: 768px) {
    .content-home-bt {
      font-size: .9rem; } }
  @media (max-width: 414px) {
    .content-home-bt {
      width: fit-content;
      display: block;
      text-transform: uppercase;
      color: #be2f37;
      text-align: center;
      font-size: 1.1rem;
      font-weight: bold;
      background-color: transparent;
      margin: 2rem 0;
      border: 15px solid;
      border-image: url(../button-border-gray.png) 18 18 stretch;
      padding: .25rem 0;
      cursor: pointer;
      text-decoration: none;
      margin: 0 auto;
      padding: .2rem 1.5rem;
      font-size: .85rem;
      border: 12px solid;
      border-image: url(../button-border-gray.png) 18 18 stretch; }
      .content-home-bt:hover {
        border-image: url(../button-border-deepGray.png) 18 18 stretch; }
      .content-home-bt:focus {
        outline: none; } }
  @media (max-width: 320px) {
    .content-home-bt {
      width: fit-content;
      display: block;
      text-transform: uppercase;
      color: #be2f37;
      text-align: center;
      font-size: 1.1rem;
      font-weight: bold;
      background-color: transparent;
      margin: 2rem 0;
      border: 15px solid;
      border-image: url(../button-border-gray.png) 18 18 stretch;
      padding: .25rem 0;
      cursor: pointer;
      text-decoration: none;
      padding: .25rem 1rem;
      margin: 0 auto;
      font-size: .85rem; }
      .content-home-bt:hover {
        border-image: url(../button-border-deepGray.png) 18 18 stretch; }
      .content-home-bt:focus {
        outline: none; } }

.content-home-white-bt {
  font-family: 'Trade Gothic',"Microsoft JhengHei","微軟正黑體";
  width: fit-content;
  display: block;
  text-transform: uppercase;
  color: #be2f37;
  text-align: center;
  font-size: 1.1rem;
  font-weight: bold;
  background-color: transparent;
  margin: 2rem 0;
  border: 15px solid;
  border-image: url(../button-border-gray.png) 18 18 stretch;
  padding: .25rem 0;
  cursor: pointer;
  text-decoration: none;
  margin: 2rem auto;
  padding: .25rem 1.5rem;
  border-image: url(../button-border-white.png) 18 18 stretch;
  color: #f3f3eb; }
  .content-home-white-bt:hover {
    border-image: url(../button-border-deepGray.png) 18 18 stretch; }
  .content-home-white-bt:focus {
    outline: none; }
  .content-home-white-bt:hover {
    border-image: url(../button-border-deepWhite.png) 18 18 stretch; }
  @media (max-width: 1280px) {
    .content-home-white-bt {
      margin: 2rem auto 0; } }
  @media (max-width: 1023px) {
    .content-home-white-bt {
      margin: 0 auto; } }
  @media (max-width: 768px) {
    .content-home-white-bt {
      font-size: .9rem;
      padding: 0 5px;
      margin: 15px auto 0; } }
  @media (max-width: 414px) {
    .content-home-white-bt {
      width: fit-content;
      display: block;
      text-transform: uppercase;
      color: #be2f37;
      text-align: center;
      font-size: 1.1rem;
      font-weight: bold;
      background-color: transparent;
      margin: 2rem 0;
      border: 15px solid;
      border-image: url(../button-border-gray.png) 18 18 stretch;
      padding: .25rem 0;
      cursor: pointer;
      text-decoration: none;
      border-image: url(../button-border-white.png) 18 18 stretch;
      margin: 15px auto 0;
      padding: 0 5px;
      font-size: .9rem;
      color: #f3f3eb; }
      .content-home-white-bt:hover {
        border-image: url(../button-border-deepGray.png) 18 18 stretch; }
      .content-home-white-bt:focus {
        outline: none; } }

.content-input-warpper {
  display: flex;
  flex-flow: wrap;
  justify-content: space-between; }

.content-input {
  width: 100%;
  height: 70px;
  border: 2px solid #d2d2d2;
  margin: 15px 0;
  padding: 0 15px;
  background-color: transparent;
  font-size: 1.3rem;
  line-height: 1.6rem;
  color: #8f8f8f; }
  .content-input:focus {
    outline: none; }
  @media (max-width: 1280px) {
    .content-input {
      font-size: 1.3rem;
      line-height: 1.5rem; } }
  @media (max-width: 768px) {
    .content-input {
      width: 100%;
      height: 50px;
      margin: 10px 0 15px;
      font-size: 1.2rem;
      line-height: 1.3rem; } }
  @media (max-width: 414px) {
    .content-input {
      margin: 15px 0 5px;
      font-size: 1rem;
      line-height: 1.2rem; } }

.content-input-full {
  width: 100%; }

.content-input-large {
  width: 100%;
  height: 80px;
  border: 2px solid #d2d2d2;
  margin: 15px 0;
  padding: 0 15px;
  background-color: transparent; }
  .content-input-large:focus {
    outline: none; }

.content-input::placeholder, .content-input-large::placeholder, .content-input-textarea::placeholder {
  color: #d2d2d2; }

.content-input::-webkit-input-placeholder, .content-input-large::-webkit-input-placeholder, .content-input-textarea::-webkit-input-placeholder {
  color: #d2d2d2; }

.content-input::-moz-placeholder, .content-input-large::-moz-placeholder, .content-input-textarea::-moz-placeholder {
  color: #d2d2d2; }

.content-input:-moz-placeholder, .content-input-large:-moz-placeholder, .content-input-textarea:-moz-placeholder {
  color: #d2d2d2; }

.content-input:-ms-input-placeholder, .content-input-large:-ms-input-placeholder, .content-input-textarea:-ms-input-placeholder {
  color: #d2d2d2; }

.content-input-textarea {
  font-family: 'Open Sans',serif;
  width: 100%;
  height: 300px;
  border: 2px solid #d2d2d2;
  margin: 15px 0;
  padding: 15px;
  font-size: 1.3rem;
  line-height: 1.6rem;
  color: #8f8f8f;
  background-color: transparent; }
  .content-input-textarea:focus {
    outline: none; }
  @media (max-width: 1280px) {
    .content-input-textarea {
      font-size: 1.3rem;
      line-height: 1.5rem; } }
  @media (max-width: 768px) {
    .content-input-textarea {
      width: 100%;
      margin: 10px 0 15px;
      font-size: 1.2rem;
      line-height: 1.3rem; } }
  @media (max-width: 414px) {
    .content-input-textarea {
      height: 200px;
      margin: 15px 0 5px;
      font-size: 1rem;
      line-height: 1.2rem; } }

.content-radio {
  display: flex;
  width: 48%;
  height: 80px;
  margin: 15px 0; }
  @media (max-width: 768px) {
    .content-radio {
      margin: 5px 0;
      height: 50px; } }
  @media (max-width: 414px) {
    .content-radio {
      margin: 15px 0 5px; } }

.content-radio-label {
  display: flex;
  align-items: center;
  color: #8f8f8f;
  font-size: 1.5rem;
  font-weight: 500;
  margin-right: 25px; }
  @media (max-width: 414px) {
    .content-radio-label {
      font-size: 1rem;
      line-height: 1.2rem; } }

.radio {
  position: relative;
  cursor: pointer;
  line-height: 20px;
  margin: 15px 10px 15px 0; }
  .radio .label {
    position: relative;
    display: block;
    float: left;
    width: 30px;
    height: 30px;
    border: 2px solid #d2d2d2;
    border-radius: 100%; }
    .radio .label:after {
      content: '';
      position: absolute;
      top: 3px;
      left: 3px;
      width: 20px;
      height: 20px;
      border-radius: 100%;
      background: #be2f37;
      transform: scale(0);
      transition: all .2s ease;
      opacity: .08;
      pointer-events: none; }
  .radio:hover label:after {
    transform: scale(3.6); }
  @media (max-width: 768px) {
    .radio .label {
      width: 20px;
      height: 20px; }
      .radio .label:after {
        width: 10px;
        height: 10px; } }

input[type="radio"]:checked + .label {
  border-color: #d2d2d2; }
  input[type="radio"]:checked + .label:after {
    transform: scale(1);
    transition: all 0.2s cubic-bezier(0.35, 0.9, 0.4, 0.9);
    opacity: 1; }

.hidden {
  display: none; }

.content-checkbox {
  display: flex;
  color: #8f8f8f;
  font-size: 1.1rem;
  line-height: 1.4rem;
  font-weight: 500;
  margin-bottom: 40px; }
  .content-checkbox > div {
    width: 20px;
    height: 20px;
    margin-right: 10px;
    margin-top: 3px; }
  .content-checkbox a {
    color: #be2f37; }
  @media (max-width: 1366px) {
    .content-checkbox {
      font-size: 1rem;
      line-height: 1.3rem; }
      .content-checkbox > div {
        width: 20px;
        height: 20px;
        margin-right: 15px; } }
  @media (max-width: 414px) {
    .content-checkbox {
      font-size: .8rem;
      line-height: 1rem; }
      .content-checkbox > div {
        width: 16px;
        height: 16px;
        margin-right: 8px; } }

input#check {
  position: absolute;
  opacity: 0; }
  input#check:checked + label svg path {
    stroke-dashoffset: 0; }
  input#check:focus + label {
    transform: scale(1.03); }

#check + label {
  display: block;
  border: 2px solid #8f8f8f;
  width: 20px;
  height: 20px;
  border-radius: 2px;
  cursor: pointer;
  transition: all .2s ease; }
  #check + label:active {
    transform: scale(1.05);
    border-radius: 3px; }
  @media (max-width: 414px) {
    #check + label {
      width: 16px;
      height: 16px; } }
  #check + label svg {
    pointer-events: none;
    padding-bottom: 3px; }
    #check + label svg path {
      fill: none;
      stroke: #be2f37;
      stroke-width: 8px;
      stroke-linecap: round;
      stroke-linejoin: round;
      stroke-dasharray: 100;
      stroke-dashoffset: 101;
      transition: all 350ms cubic-bezier(1, 0, 0.37, 0.91); }

.dividing-inner-text {
  font-size: 1.5rem;
  font-weight: 400;
  line-height: 1rem;
  color: #5a5a5a;
  text-align: center;
  line-height: 1.5rem;
  background-color: #f3f2eb;
  margin: 0 auto;
  width: fit-content;
  padding: 0 40px; }
  @media (max-width: 768px) {
    .dividing-inner-text {
      font-size: 1.2rem;
      line-height: 1.2rem;
      padding: 0 20px; } }
  @media (max-width: 414px) {
    .dividing-inner-text {
      font-size: 1rem;
      line-height: 1.2rem;
      padding: 0 20px; } }

.gray-line-horizontal {
  border: none;
  border-top: 0.5px solid #d2d2d2;
  border-bottom: 0.5px solid #d2d2d2;
  width: 100%; }
  @media (max-width: 1023px) {
    .gray-line-horizontal {
      display: none; } }

.gray-line {
  border-style: solid;
  border-color: #d2d2d2;
  border-width: 0.5px; }

.content-special-area {
  width: 100%;
  min-height: 500px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  align-items: center;
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding: 40px 20%; }
  .content-special-area i {
    width: 40px;
    height: 30px; }
  .content-special-area div {
    text-align: center; }
  @media (max-width: 1650px) {
    .content-special-area {
      padding: 40px 15%; } }
  @media (max-width: 1450px) {
    .content-special-area {
      padding: 40px 10%; } }
  @media (max-width: 1366px) {
    .content-special-area {
      padding: 40px 5%; } }
  @media (max-width: 414px) {
    .content-special-area {
      min-height: auto; } }

.home-content-warp {
  display: flex; }
  @media (max-width: 1023px) {
    .home-content-warp {
      flex-direction: column; } }

.home-content-left-warp {
  display: flex;
  padding: 0 3% 0 0;
  width: 37%;
  flex-direction: column; }
  @media (max-width: 1023px) {
    .home-content-left-warp {
      padding: 0 3%;
      width: 100%; } }

.home-content-right-warp {
  width: 37.5%;
  display: flex;
  padding: 0 0 0 3%;
  flex-direction: column; }
  @media (max-width: 1023px) {
    .home-content-right-warp {
      padding: 0 3%;
      width: 100%; } }

.home-content-img {
  height: 620px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  margin: 40px 0; }
  @media (max-width: 1023px) {
    .home-content-img {
      background-size: contain; } }
  @media (max-width: 414px) {
    .home-content-img {
      height: 450px; } }

.home-content-center {
  display: flex;
  padding: 0 3%;
  width: 24%;
  flex-direction: column;
  justify-content: space-evenly;
  text-align: center;
  border-left: 1px solid #d2d2d2;
  border-right: 1px solid #d2d2d2; }
  @media (max-width: 1023px) {
    .home-content-center {
      width: 100%;
      flex-direction: row;
      padding: 3% 0;
      border: none;
      border-top: 1px solid #d2d2d2;
      border-bottom: 1px solid #d2d2d2; } }

.home-content-rightup-warp {
  width: 62.5%;
  display: flex;
  padding: 0 3%;
  flex-direction: column;
  border-left: 1px solid #d2d2d2; }
  @media (max-width: 1023px) {
    .home-content-rightup-warp {
      border: none;
      width: 100%; } }

.home-content-banner {
  width: 100%;
  position: relative; }

.home-banner-blackCurtain {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.4); }

.home-banner-cut {
  display: flex;
  width: 100%;
  height: 20px;
  top: 0;
  left: 0;
  position: relative;
  margin-bottom: -20px; }

.home-banner-cutSide {
  height: 20px;
  width: 100%;
  background-color: #f3f3eb; }
  @media (max-width: 1023px) {
    .home-banner-cutSide {
      width: 15%; } }

.home-banner-cutMiddle {
  height: 20px;
  width: 100%;
  background-image: url("../cutout-molding-left.png"), url("../cutout-molding-right.png");
  background-repeat: no-repeat;
  background-position: 0 0,100% 0;
  background-size: contain; }
  @media (max-width: 1023px) {
    .home-banner-cutMiddle {
      width: 70%; } }

.home-banner-img {
  width: 100%;
  height: 500px;
  background-size: cover; }
  @media (max-width: 1280px) {
    .home-banner-img {
      height: 450px; } }
  @media (max-width: 768px) {
    .home-banner-img {
      height: 400px; } }
  @media (max-width: 414px) {
    .home-banner-img {
      height: 300px; } }

.home-content-img-cow {
  height: 120px;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  margin: 20px 0; }

.home-banner-container {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #fff;
  padding: 0 10%;
  text-align: center; }
  @media (max-width: 1280px) {
    .home-banner-container {
      padding: 0 5%; } }

.home-content-inner-text {
  font-size: 1.5rem;
  line-height: 1.8rem;
  color: #f3f3eb; }
  @media (max-width: 768px) {
    .home-content-inner-text {
      font-size: 1.2rem;
      line-height: 1.3rem; } }
  @media (max-width: 414px) {
    .home-content-inner-text {
      font-size: 1rem;
      line-height: 1.2rem; } }

.home-content-sub-title {
  font-family: 'Trade Gothic',"Microsoft JhengHei","微軟正黑體";
  font-size: 2.5rem;
  color: #f3f3eb;
  text-transform: uppercase;
  line-height: 1;
  font-weight: 700;
  letter-spacing: -1px; }
  @media (max-width: 768px) {
    .home-content-sub-title {
      font-size: 2rem; } }
  @media (max-width: 414px) {
    .home-content-sub-title {
      font-size: 1.5rem; } }

.home-show {
  display: inline-table; }

.food-detail-title-warp {
  width: 50%;
  margin: 100px auto 50px; }
  @media (max-width: 1280px) {
    .food-detail-title-warp {
      width: 90%;
      margin: 70px auto 35px; } }
  @media (max-width: 768px) {
    .food-detail-title-warp {
      margin: 50px auto 35px; } }
  @media (max-width: 414px) {
    .food-detail-title-warp {
      margin: 35px auto 20px; } }

.food-detail-inner-warpper {
  width: fit-content;
  margin: 50px auto; }
  @media (max-width: 1280px) {
    .food-detail-inner-warpper {
      margin: 35px auto; } }
  @media (max-width: 414px) {
    .food-detail-inner-warpper {
      margin: 20px auto; } }

.food-detail-line {
  width: 1160px;
  margin: 10px auto 60px; }
  @media (max-width: 1366px) {
    .food-detail-line {
      width: 90%; } }
  @media (max-width: 414px) {
    .food-detail-line {
      margin: 10px auto 25px; } }

.lunch-content-title {
  font-family: 'Trade Gothic',"Microsoft JhengHei","微軟正黑體";
  letter-spacing: -1px;
  width: 100%;
  font-weight: 800;
  margin: 25px auto;
  text-transform: uppercase;
  width: 100%;
  font-size: 3.5rem;
  color: #333;
  text-align: center; }
  @media (max-width: 1366px) {
    .lunch-content-title {
      font-size: 3rem; } }
  @media (max-width: 1023px) {
    .lunch-content-title {
      font-size: 2.8rem; } }
  @media (max-width: 768px) {
    .lunch-content-title {
      font-size: 2.5rem; } }
  @media (max-width: 414px) {
    .lunch-content-title {
      font-size: 1.5rem;
      margin: 25px auto; } }

.lunch-title-inner-warpper {
  width: 1160px;
  margin: 0 auto 50px; }
  @media (max-width: 1366px) {
    .lunch-title-inner-warpper {
      width: 90%;
      margin: 0 auto 35px; } }
  @media (max-width: 768px) {
    .lunch-title-inner-warpper {
      margin: 0 auto 35px; } }
  @media (max-width: 414px) {
    .lunch-title-inner-warpper {
      margin: 0 auto 20px; } }

.lunch-inner-subtitle {
  font-family: 'Trade Gothic',"Microsoft JhengHei","微軟正黑體";
  letter-spacing: -1px;
  font-size: 1.6rem;
  line-height: 1.8rem;
  color: #333;
  text-align: center;
  font-weight: 600; }
  @media (max-width: 414px) {
    .lunch-inner-subtitle {
      font-size: 1rem;
      line-height: 1.3rem; } }

.content-lunch-area {
  width: 100%;
  min-height: 500px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  align-items: center;
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding: 40px 20% 0; }
  .content-lunch-area i {
    width: 40px;
    height: 30px; }
  .content-lunch-area div {
    text-align: center; }
  @media (max-width: 1650px) {
    .content-lunch-area {
      padding: 40px 15% 0; } }
  @media (max-width: 1450px) {
    .content-lunch-area {
      padding: 40px 10% 0; } }
  @media (max-width: 1366px) {
    .content-lunch-area {
      padding: 0 5%; } }
  @media (max-width: 414px) {
    .content-lunch-area {
      min-height: auto; } }

.lunch-content-italic {
  color: #8f8f8f;
  width: 90%;
  margin: 0 auto;
  font-style: italic;
  text-align: center;
  font-weight: 400;
  font-size: 1.2rem;
  line-height: 1.5rem; }
  @media (max-width: 414px) {
    .lunch-content-italic {
      font-size: .8rem; } }

.lunch-block {
  width: 100%;
  height: 50px; }

.happy-hour-attr {
  display: flex;
  padding: 50px 0;
  justify-content: space-between; }
  @media (max-width: 1023px) {
    .happy-hour-attr {
      flex-direction: column;
      padding: 0;
      margin: 0 auto; } }

.happy-hour-content {
  display: flex;
  width: 45%;
  flex-direction: column; }
  @media (max-width: 1023px) {
    .happy-hour-content {
      width: 100%; } }

.happy-hour-bottom {
  margin-bottom: 40px; }

.food-detail-warpper h1 {
  font-family: 'Trade Gothic',"Microsoft JhengHei","微軟正黑體";
  letter-spacing: -1px;
  width: 100%;
  font-weight: 800;
  margin: 0 auto;
  text-transform: uppercase;
  font-size: 3rem;
  color: #333;
  text-align: center; }
  @media (max-width: 1366px) {
    .food-detail-warpper h1 {
      font-size: 2.5rem; } }
  @media (max-width: 768px) {
    .food-detail-warpper h1 {
      font-size: 2rem; } }
  @media (max-width: 414px) {
    .food-detail-warpper h1 {
      font-size: 1.5rem;
      letter-spacing: -1px; } }

.food-detail-warpper h2 {
  width: 100%;
  margin: 0 auto;
  font-size: 1.5rem;
  line-height: 1.8rem;
  color: #5a5a5a;
  text-align: center; }
  @media (max-width: 1366px) {
    .food-detail-warpper h2 {
      font-size: 1.3rem; } }
  @media (max-width: 768px) {
    .food-detail-warpper h2 {
      font-size: 1.2rem; } }
  @media (max-width: 414px) {
    .food-detail-warpper h2 {
      width: 90%;
      line-height: 1.2rem; } }

.food-detail-warpper h3 {
  width: 100%;
  margin: 0 auto;
  color: #8f8f8f;
  font-weight: 400;
  font-size: 1.2rem;
  padding-bottom: .4em;
  text-align: center; }
  @media (max-width: 414px) {
    .food-detail-warpper h3 {
      font-size: 1.2rem; } }

.food-detail-warpper a {
  width: 100%;
  width: fit-content;
  display: block;
  text-transform: uppercase;
  color: #be2f37;
  text-align: center;
  font-size: 1.1rem;
  font-weight: bold;
  background-color: transparent;
  margin: 2rem 0;
  border: 15px solid;
  border-image: url(../button-border-gray.png) 18 18 stretch;
  padding: .25rem 0;
  cursor: pointer;
  text-decoration: none;
  margin: 15px auto;
  padding: 0 1rem;
  font-size: 1.2rem; }
  .food-detail-warpper a:hover {
    border-image: url(../button-border-deepGray.png) 18 18 stretch; }
  .food-detail-warpper a:focus {
    outline: none; }
  @media (max-width: 414px) {
    .food-detail-warpper a {
      width: fit-content;
      display: block;
      text-transform: uppercase;
      color: #be2f37;
      text-align: center;
      font-size: 1.1rem;
      font-weight: bold;
      background-color: transparent;
      margin: 2rem 0;
      border: 15px solid;
      border-image: url(../button-border-gray.png) 18 18 stretch;
      padding: .25rem 0;
      cursor: pointer;
      text-decoration: none;
      margin: 10px auto;
      padding: 0 1rem;
      font-size: .9rem; }
      .food-detail-warpper a:hover {
        border-image: url(../button-border-deepGray.png) 18 18 stretch; }
      .food-detail-warpper a:focus {
        outline: none; } }
  @media (max-width: 320px) {
    .food-detail-warpper a {
      width: fit-content;
      display: block;
      text-transform: uppercase;
      color: #be2f37;
      text-align: center;
      font-size: 1.1rem;
      font-weight: bold;
      background-color: transparent;
      margin: 2rem 0;
      border: 15px solid;
      border-image: url(../button-border-gray.png) 18 18 stretch;
      padding: .25rem 0;
      cursor: pointer;
      text-decoration: none;
      margin: 10px auto;
      padding: 0 1rem; }
      .food-detail-warpper a:hover {
        border-image: url(../button-border-deepGray.png) 18 18 stretch; }
      .food-detail-warpper a:focus {
        outline: none; } }

.food-detail-warpper img {
  margin: 0 auto;
  display: block;
  max-width: 55%; }
  @media (max-width: 414px) {
    .food-detail-warpper img {
      max-width: 100%; } }

.food-detail-warpper div {
  width: 1160px;
  margin: 0 auto; }
  @media (max-width: 1366px) {
    .food-detail-warpper div {
      width: 80%; } }
  @media (max-width: 414px) {
    .food-detail-warpper div {
      width: 90%; } }

.food-detail-warpper p {
  width: 100%;
  margin: 0 auto;
  color: #8f8f8f;
  font-weight: 400;
  font-size: 1.2rem;
  padding: 0 0 10px;
  letter-spacing: 1px;
  text-align: center; }
  @media (max-width: 414px) {
    .food-detail-warpper p {
      padding: 0 0 8px;
      font-size: .9rem;
      line-height: 1rem; } }

.en-food-detail-warpper h1 {
  font-family: 'Trade Gothic',"Microsoft JhengHei","微軟正黑體";
  letter-spacing: -1px;
  width: 100%;
  font-weight: 800;
  margin: 0 auto;
  text-transform: uppercase;
  font-size: 3rem;
  color: #333;
  text-align: center; }
  @media (max-width: 1366px) {
    .en-food-detail-warpper h1 {
      font-size: 2.5rem; } }
  @media (max-width: 768px) {
    .en-food-detail-warpper h1 {
      font-size: 2rem; } }
  @media (max-width: 414px) {
    .en-food-detail-warpper h1 {
      width: 90%;
      font-size: 1.5rem;
      letter-spacing: -1px; } }

.en-food-detail-warpper h2 {
  width: 80%;
  margin: 0 auto;
  font-size: 1.5rem;
  line-height: 1.8rem;
  color: #5a5a5a;
  text-align: center; }
  @media (max-width: 1366px) {
    .en-food-detail-warpper h2 {
      font-size: 1.3rem; } }
  @media (max-width: 768px) {
    .en-food-detail-warpper h2 {
      font-size: 1.2rem; } }
  @media (max-width: 414px) {
    .en-food-detail-warpper h2 {
      width: 90%;
      font-size: 1.1rem;
      line-height: 1.3rem; } }

.en-food-detail-warpper h3 {
  width: 80%;
  margin: 0 auto;
  color: #8f8f8f;
  font-weight: 400;
  font-size: 1.2rem;
  padding-bottom: .4em;
  text-align: center; }
  @media (max-width: 414px) {
    .en-food-detail-warpper h3 {
      width: 90%;
      font-size: 1.2rem; } }

.en-food-detail-warpper a {
  width: 80%;
  width: fit-content;
  display: block;
  text-transform: uppercase;
  color: #be2f37;
  text-align: center;
  font-size: 1.1rem;
  font-weight: bold;
  background-color: transparent;
  margin: 2rem 0;
  border: 15px solid;
  border-image: url(../button-border-gray.png) 18 18 stretch;
  padding: .25rem 0;
  cursor: pointer;
  text-decoration: none;
  margin: 15px auto;
  padding: 0 1rem;
  font-size: 1.2rem; }
  .en-food-detail-warpper a:hover {
    border-image: url(../button-border-deepGray.png) 18 18 stretch; }
  .en-food-detail-warpper a:focus {
    outline: none; }
  @media (max-width: 414px) {
    .en-food-detail-warpper a {
      width: 90%;
      width: fit-content;
      display: block;
      text-transform: uppercase;
      color: #be2f37;
      text-align: center;
      font-size: 1.1rem;
      font-weight: bold;
      background-color: transparent;
      margin: 2rem 0;
      border: 15px solid;
      border-image: url(../button-border-gray.png) 18 18 stretch;
      padding: .25rem 0;
      cursor: pointer;
      text-decoration: none;
      margin: 10px auto;
      padding: 0 1rem;
      font-size: .9rem; }
      .en-food-detail-warpper a:hover {
        border-image: url(../button-border-deepGray.png) 18 18 stretch; }
      .en-food-detail-warpper a:focus {
        outline: none; } }
  @media (max-width: 320px) {
    .en-food-detail-warpper a {
      width: fit-content;
      display: block;
      text-transform: uppercase;
      color: #be2f37;
      text-align: center;
      font-size: 1.1rem;
      font-weight: bold;
      background-color: transparent;
      margin: 2rem 0;
      border: 15px solid;
      border-image: url(../button-border-gray.png) 18 18 stretch;
      padding: .25rem 0;
      cursor: pointer;
      text-decoration: none;
      margin: 10px auto;
      padding: 0 1rem; }
      .en-food-detail-warpper a:hover {
        border-image: url(../button-border-deepGray.png) 18 18 stretch; }
      .en-food-detail-warpper a:focus {
        outline: none; } }

.en-food-detail-warpper img {
  margin: 0 auto;
  display: block;
  max-width: 55%; }
  @media (max-width: 414px) {
    .en-food-detail-warpper img {
      max-width: 100%; } }

.en-food-detail-warpper div {
  width: 1160px;
  margin: 0 auto; }
  @media (max-width: 1366px) {
    .en-food-detail-warpper div {
      width: 80%; } }
  @media (max-width: 414px) {
    .en-food-detail-warpper div {
      width: 90%; } }

.en-food-detail-warpper p {
  width: fit-content;
  margin: 0 auto;
  color: #8f8f8f;
  font-weight: 400;
  font-size: 1.3rem;
  line-height: 1.8rem;
  padding: 0 0 10px;
  text-align: center; }
  @media (max-width: 414px) {
    .en-food-detail-warpper p {
      padding: 0 0 8px;
      width: 90%;
      font-size: 1rem;
      line-height: 1.2rem; } }

.foodDetail-special-area {
  width: 100%;
  height: 500px;
  background-image: url("../food-detail-04.jpg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  align-items: center;
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding: 40px 20%;
  margin-top: 30px; }
  .foodDetail-special-area .content-inner {
    text-align: justify;
    text-align-last: center; }
  .foodDetail-special-area .en-content-inner {
    text-align: center; }
  .foodDetail-special-area h1 {
    font-family: 'Trade Gothic',"Microsoft JhengHei","微軟正黑體";
    letter-spacing: -1px;
    width: 100%;
    font-weight: 800;
    margin: 0 auto;
    text-transform: uppercase;
    font-size: 3rem;
    color: #333;
    text-align: center; }
    @media (max-width: 1366px) {
      .foodDetail-special-area h1 {
        font-size: 2.5rem; } }
    @media (max-width: 768px) {
      .foodDetail-special-area h1 {
        font-size: 2rem; } }
    @media (max-width: 414px) {
      .foodDetail-special-area h1 {
        font-size: 1.5rem;
        letter-spacing: -1px; } }
  @media (max-width: 1650px) {
    .foodDetail-special-area {
      padding: 40px 15%; } }
  @media (max-width: 1450px) {
    .foodDetail-special-area {
      padding: 40px 10%; } }
  @media (max-width: 1366px) {
    .foodDetail-special-area {
      height: auto;
      padding: 100px 5%; } }
  @media (max-width: 414px) {
    .foodDetail-special-area {
      padding: 40px 5%; } }

.foodDetail-icon {
  width: 50px;
  height: 40px; }
  @media (max-width: 1280px) {
    .foodDetail-icon {
      width: 40px;
      height: 30px; } }
  @media (max-width: 414px) {
    .foodDetail-icon {
      width: 30px;
      height: 20px; } }

.promotion-detail-warpper {
  width: 1160px;
  margin: 20px auto 30px; }
  @media (max-width: 1366px) {
    .promotion-detail-warpper {
      width: 80%; } }
  @media (max-width: 414px) {
    .promotion-detail-warpper {
      margin: 20px auto 20px;
      width: 90%; } }
  .promotion-detail-warpper h1 {
    font-family: 'Trade Gothic',"Microsoft JhengHei","微軟正黑體";
    letter-spacing: -1px;
    width: 80%;
    font-weight: 800;
    margin: 0 auto;
    text-transform: uppercase;
    font-size: 3.5rem;
    line-height: 3.5rem;
    color: #333;
    text-align: center; }
    @media (max-width: 1023px) {
      .promotion-detail-warpper h1 {
        font-size: 3rem;
        line-height: 3rem; } }
    @media (max-width: 768px) {
      .promotion-detail-warpper h1 {
        font-size: 2.5rem;
        line-height: 2.5rem; } }
    @media (max-width: 414px) {
      .promotion-detail-warpper h1 {
        width: 90%;
        font-size: 1.5rem;
        line-height: 1.8rem;
        letter-spacing: -1px; } }
  .promotion-detail-warpper h2 {
    font-family: 'Trade Gothic',"Microsoft JhengHei","微軟正黑體";
    letter-spacing: -1px;
    width: 80%;
    margin: 0 auto;
    font-size: 1.5rem;
    line-height: 1.8rem;
    color: #5a5a5a;
    text-align: center; }
    @media (max-width: 414px) {
      .promotion-detail-warpper h2 {
        width: 90%;
        font-size: 1rem;
        line-height: 1.3rem; } }
  .promotion-detail-warpper h3 {
    width: 80%;
    margin: 0 auto;
    color: #8f8f8f;
    font-weight: 400;
    font-size: 1.2rem;
    padding-bottom: .4em;
    text-align: center; }
    @media (max-width: 414px) {
      .promotion-detail-warpper h3 {
        width: 90%;
        font-size: 1.2rem; } }
  .promotion-detail-warpper a {
    width: 80%;
    width: fit-content;
    display: block;
    text-transform: uppercase;
    color: #be2f37;
    text-align: center;
    font-size: 1.1rem;
    font-weight: bold;
    background-color: transparent;
    margin: 2rem 0;
    border: 15px solid;
    border-image: url(../button-border-gray.png) 18 18 stretch;
    padding: .25rem 0;
    cursor: pointer;
    text-decoration: none;
    margin: 15px auto;
    padding: 0 1rem;
    font-size: 1.2rem; }
    .promotion-detail-warpper a:hover {
      border-image: url(../button-border-deepGray.png) 18 18 stretch; }
    .promotion-detail-warpper a:focus {
      outline: none; }
    @media (max-width: 414px) {
      .promotion-detail-warpper a {
        width: 90%;
        width: fit-content;
        display: block;
        text-transform: uppercase;
        color: #be2f37;
        text-align: center;
        font-size: 1.1rem;
        font-weight: bold;
        background-color: transparent;
        margin: 2rem 0;
        border: 15px solid;
        border-image: url(../button-border-gray.png) 18 18 stretch;
        padding: .25rem 0;
        cursor: pointer;
        text-decoration: none;
        margin: 10px auto;
        padding: 0 1rem;
        font-size: .9rem; }
        .promotion-detail-warpper a:hover {
          border-image: url(../button-border-deepGray.png) 18 18 stretch; }
        .promotion-detail-warpper a:focus {
          outline: none; } }
    @media (max-width: 320px) {
      .promotion-detail-warpper a {
        width: fit-content;
        display: block;
        text-transform: uppercase;
        color: #be2f37;
        text-align: center;
        font-size: 1.1rem;
        font-weight: bold;
        background-color: transparent;
        margin: 2rem 0;
        border: 15px solid;
        border-image: url(../button-border-gray.png) 18 18 stretch;
        padding: .25rem 0;
        cursor: pointer;
        text-decoration: none;
        margin: 10px auto;
        padding: 0 1rem; }
        .promotion-detail-warpper a:hover {
          border-image: url(../button-border-deepGray.png) 18 18 stretch; }
        .promotion-detail-warpper a:focus {
          outline: none; } }
  .promotion-detail-warpper img {
    margin: 0 auto;
    display: block;
    max-width: 100%; }
  .promotion-detail-warpper div {
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    justify-content: center; }
  .promotion-detail-warpper p {
    width: 100%;
    margin: 0 auto;
    color: #8f8f8f;
    font-weight: 400;
    font-size: 1.2rem;
    padding: 20px 0;
    letter-spacing: 1px;
    text-align: center; }
    @media (max-width: 768px) {
      .promotion-detail-warpper p {
        line-height: 1.4rem; } }
    @media (max-width: 414px) {
      .promotion-detail-warpper p {
        padding: 10px 5px;
        font-size: .9rem;
        line-height: 1.2rem; } }
  .promotion-detail-warpper .promotion-inner {
    width: 50%;
    padding: 2rem 1.5rem;
    font-size: 1.5rem;
    color: #333;
    background-color: #e8e6da;
    margin: 0 auto; }
    @media (max-width: 1366px) {
      .promotion-detail-warpper .promotion-inner {
        padding: 20px; } }
    @media (max-width: 414px) {
      .promotion-detail-warpper .promotion-inner {
        width: 100%;
        background-color: transparent;
        padding: 15px 0 0; } }
    .promotion-detail-warpper .promotion-inner h2 {
      width: 100%;
      text-align: start; }
    .promotion-detail-warpper .promotion-inner p {
      padding: 0;
      text-align: start;
      width: 100%; }
      @media (max-width: 1366px) {
        .promotion-detail-warpper .promotion-inner p {
          font-size: 1.2rem; } }
      @media (max-width: 414px) {
        .promotion-detail-warpper .promotion-inner p {
          font-size: .9rem;
          padding: 1px 0;
          line-height: 1rem; } }
  .promotion-detail-warpper .promotion-inner-bg {
    background-image: linear-gradient(rgba(200, 200, 200, 0.6), rgba(160, 160, 160, 0.6)); }
    .promotion-detail-warpper .promotion-inner-bg p {
      color: #5a5a5a; }
    @media (max-width: 414px) {
      .promotion-detail-warpper .promotion-inner-bg {
        background-image: none; }
        .promotion-detail-warpper .promotion-inner-bg p {
          color: #8f8f8f; } }
  .promotion-detail-warpper .promotion-inner-bg2 {
    background-image: linear-gradient(rgba(200, 200, 200, 0.6), rgba(160, 160, 160, 0.6)); }
    .promotion-detail-warpper .promotion-inner-bg2 p {
      color: #5a5a5a; }
    @media (max-width: 414px) {
      .promotion-detail-warpper .promotion-inner-bg2 {
        background-image: none; }
        .promotion-detail-warpper .promotion-inner-bg2 p {
          color: #8f8f8f; } }

.en-promotion-detail-warpper {
  width: 1160px;
  margin: 20px auto 30px; }
  @media (max-width: 1366px) {
    .en-promotion-detail-warpper {
      width: 80%; } }
  @media (max-width: 414px) {
    .en-promotion-detail-warpper {
      margin: 20px auto 20px;
      width: 90%; } }
  .en-promotion-detail-warpper h1 {
    font-family: 'Trade Gothic',"Microsoft JhengHei","微軟正黑體";
    letter-spacing: -1px;
    width: 80%;
    font-weight: 800;
    margin: 0 auto;
    text-transform: uppercase;
    font-size: 3.5rem;
    line-height: 3.5rem;
    color: #333;
    text-align: center; }
    @media (max-width: 1023px) {
      .en-promotion-detail-warpper h1 {
        font-size: 3rem;
        line-height: 3rem; } }
    @media (max-width: 768px) {
      .en-promotion-detail-warpper h1 {
        font-size: 2.5rem;
        line-height: 2.5rem; } }
    @media (max-width: 414px) {
      .en-promotion-detail-warpper h1 {
        width: 90%;
        font-size: 1.5rem;
        line-height: 1.6rem; } }
  .en-promotion-detail-warpper h2 {
    font-family: 'Trade Gothic',"Microsoft JhengHei","微軟正黑體";
    letter-spacing: -1px;
    width: 80%;
    margin: 0 auto;
    font-size: 1.5rem;
    line-height: 1.8rem;
    color: #5a5a5a;
    text-align: center; }
    @media (max-width: 414px) {
      .en-promotion-detail-warpper h2 {
        width: 90%;
        font-size: 1rem;
        line-height: 1.3rem; } }
  .en-promotion-detail-warpper h3 {
    width: 80%;
    margin: 0 auto;
    color: #8f8f8f;
    font-weight: 400;
    font-size: 1.2rem;
    padding-bottom: .4em;
    text-align: center; }
    @media (max-width: 414px) {
      .en-promotion-detail-warpper h3 {
        width: 90%;
        font-size: 1.2rem; } }
  .en-promotion-detail-warpper a {
    width: 80%;
    width: fit-content;
    display: block;
    text-transform: uppercase;
    color: #be2f37;
    text-align: center;
    font-size: 1.1rem;
    font-weight: bold;
    background-color: transparent;
    margin: 2rem 0;
    border: 15px solid;
    border-image: url(../button-border-gray.png) 18 18 stretch;
    padding: .25rem 0;
    cursor: pointer;
    text-decoration: none;
    margin: 15px auto;
    padding: 0 1rem;
    font-size: 1.2rem; }
    .en-promotion-detail-warpper a:hover {
      border-image: url(../button-border-deepGray.png) 18 18 stretch; }
    .en-promotion-detail-warpper a:focus {
      outline: none; }
    @media (max-width: 414px) {
      .en-promotion-detail-warpper a {
        width: 90%;
        width: fit-content;
        display: block;
        text-transform: uppercase;
        color: #be2f37;
        text-align: center;
        font-size: 1.1rem;
        font-weight: bold;
        background-color: transparent;
        margin: 2rem 0;
        border: 15px solid;
        border-image: url(../button-border-gray.png) 18 18 stretch;
        padding: .25rem 0;
        cursor: pointer;
        text-decoration: none;
        margin: 10px auto;
        padding: 0 1rem;
        font-size: .9rem; }
        .en-promotion-detail-warpper a:hover {
          border-image: url(../button-border-deepGray.png) 18 18 stretch; }
        .en-promotion-detail-warpper a:focus {
          outline: none; } }
    @media (max-width: 320px) {
      .en-promotion-detail-warpper a {
        width: fit-content;
        display: block;
        text-transform: uppercase;
        color: #be2f37;
        text-align: center;
        font-size: 1.1rem;
        font-weight: bold;
        background-color: transparent;
        margin: 2rem 0;
        border: 15px solid;
        border-image: url(../button-border-gray.png) 18 18 stretch;
        padding: .25rem 0;
        cursor: pointer;
        text-decoration: none;
        margin: 10px auto;
        padding: 0 1rem; }
        .en-promotion-detail-warpper a:hover {
          border-image: url(../button-border-deepGray.png) 18 18 stretch; }
        .en-promotion-detail-warpper a:focus {
          outline: none; } }
  .en-promotion-detail-warpper img {
    margin: 0 auto;
    display: block;
    max-width: 100%; }
  .en-promotion-detail-warpper div {
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    justify-content: center; }
  .en-promotion-detail-warpper p {
    width: 100%;
    margin: 0 auto;
    color: #8f8f8f;
    font-weight: 400;
    font-size: 1.3rem;
    line-height: 1.8rem;
    padding: 20px 10px;
    text-align: center; }
    @media (max-width: 768px) {
      .en-promotion-detail-warpper p {
        font-size: 1.2rem;
        line-height: 1.4rem; } }
    @media (max-width: 414px) {
      .en-promotion-detail-warpper p {
        width: 100%;
        padding: 10px 10px;
        width: 100%;
        font-size: 1rem;
        line-height: 1.2rem; } }
  .en-promotion-detail-warpper .promotion-inner {
    width: 50%;
    padding: 2rem 1.5rem;
    font-size: 1.5rem;
    color: #333;
    background-color: #e8e6da;
    margin: 0 auto; }
    @media (max-width: 414px) {
      .en-promotion-detail-warpper .promotion-inner {
        width: 100%;
        background-color: transparent;
        padding: 15px 0 0; } }
    .en-promotion-detail-warpper .promotion-inner h2 {
      width: 100%;
      text-align: start; }
    .en-promotion-detail-warpper .promotion-inner p {
      padding: 0;
      text-align: start;
      width: 100%; }
      @media (max-width: 414px) {
        .en-promotion-detail-warpper .promotion-inner p {
          padding: 1px 0;
          line-height: 1rem; } }
  .en-promotion-detail-warpper .promotion-inner-bg {
    background-image: linear-gradient(rgba(200, 200, 200, 0.6), rgba(160, 160, 160, 0.6)); }
    .en-promotion-detail-warpper .promotion-inner-bg p {
      color: #5a5a5a; }
    @media (max-width: 414px) {
      .en-promotion-detail-warpper .promotion-inner-bg {
        background-image: none; }
        .en-promotion-detail-warpper .promotion-inner-bg p {
          color: #8f8f8f; } }
  .en-promotion-detail-warpper .promotion-inner-bg2 {
    background-image: linear-gradient(rgba(200, 200, 200, 0.6), rgba(160, 160, 160, 0.6)); }
    .en-promotion-detail-warpper .promotion-inner-bg2 p {
      color: #5a5a5a; }
    @media (max-width: 414px) {
      .en-promotion-detail-warpper .promotion-inner-bg2 {
        background-image: none; }
        .en-promotion-detail-warpper .promotion-inner-bg2 p {
          color: #8f8f8f; } }

.drink-inner-title-warpper {
  width: 70%;
  margin: 88px auto 50px; }
  @media (max-width: 1450px) {
    .drink-inner-title-warpper {
      width: 65%; } }
  @media (max-width: 1280px) {
    .drink-inner-title-warpper {
      width: 55%; } }
  @media (max-width: 1023px) {
    .drink-inner-title-warpper {
      width: 75%;
      margin: 88px auto 35px; } }
  @media (max-width: 768px) {
    .drink-inner-title-warpper {
      width: 85%;
      margin: 75px auto 35px; } }
  @media (max-width: 414px) {
    .drink-inner-title-warpper {
      width: 90%;
      margin: 45px auto 20px; } }

.about-us-video-size {
  margin: 0 auto 140px;
  max-width: 60%;
  max-height: 33.75vw; }
  @media (max-width: 1400px) {
    .about-us-video-size {
      max-width: 70%;
      max-height: 39.35vw; } }
  @media (max-width: 414px) {
    .about-us-video-size {
      max-width: 90%;
      max-height: 50.8vw;
      margin: 0 auto 120px; } }
  @media (max-width: 375px) {
    .about-us-video-size {
      max-height: 50.55vw; } }
  @media (max-width: 375px) {
    .about-us-video-size {
      max-height: 50.47vw; } }

@media (max-width: 1280px) {
  .private-events-title {
    letter-spacing: 0; } }

.food-detail-text-italic {
  color: #8f8f8f;
  font-style: italic;
  font-weight: 500;
  font-size: 1.5rem;
  margin-bottom: 5px; }
  @media (max-width: 1366px) {
    .food-detail-text-italic {
      font-size: 1.2rem; } }
  @media (max-width: 768px) {
    .food-detail-text-italic {
      margin-bottom: 2px;
      font-size: 1rem; } }
  @media (max-width: 414px) {
    .food-detail-text-italic {
      font-size: .8rem; } }

.menber-title {
  font-family: 'Trade Gothic',"Microsoft JhengHei","微軟正黑體";
  letter-spacing: -1px;
  color: #5a5a5a;
  font-size: 1.8rem;
  line-height: 1.8rem;
  font-weight: 700;
  margin-bottom: 20px; }
  @media (max-width: 1366px) {
    .menber-title {
      font-size: 1.5rem;
      line-height: 1.8rem;
      margin-bottom: 15px; } }
  @media (max-width: 414px) {
    .menber-title {
      margin-bottom: 10px;
      font-size: 1.1rem;
      line-height: 1.3rem; } }

.privacy-inner {
  color: #5a5a5a; }
  .privacy-inner h1 {
    font-size: 1.5rem;
    line-height: 2rem; }
  .privacy-inner p {
    font-size: 1.2rem;
    line-height: 1.6rem;
    margin: 5px auto; }
  .privacy-inner a {
    font-size: 1.2rem;
    line-height: 1.6rem;
    margin: 5px auto;
    color: #be2f37; }
  @media (max-width: 414px) {
    .privacy-inner h1 {
      font-size: 1.1rem;
      line-height: 1.4rem; }
    .privacy-inner p {
      font-size: .9rem;
      line-height: 1.2rem;
      margin: 5px auto; }
    .privacy-inner a {
      font-size: .9rem;
      line-height: 1.2rem;
      margin: 5px auto; } }

.en-privacy-inner {
  color: #5a5a5a; }
  .en-privacy-inner h1 {
    font-family: 'Trade Gothic',"Microsoft JhengHei","微軟正黑體";
    letter-spacing: -1px;
    font-size: 1.5rem;
    line-height: 2rem; }
  .en-privacy-inner p {
    font-size: 1.2rem;
    line-height: 1.6rem;
    margin: 5px auto; }
  .en-privacy-inner a {
    font-size: 1.2rem;
    line-height: 1.6rem;
    margin: 5px auto;
    color: #be2f37; }
  @media (max-width: 414px) {
    .en-privacy-inner h1 {
      font-size: 1.2rem;
      line-height: 1.5rem; }
    .en-privacy-inner p {
      font-size: .9rem;
      line-height: 1.1rem;
      margin: 5px auto; }
    .en-privacy-inner a {
      font-size: .9rem;
      line-height: 1.1rem;
      margin: 5px auto; } }

.food-steak-list-img {
  margin: 15px 0 0; }
  @media (max-width: 1023px) {
    .food-steak-list-img {
      margin: 0; } }

.display-none {
  display: none; }

.display-block {
  display: block; }

.display-inline-block {
  display: inline-block; }
