/* home-03 */
.home3-nav-list-wrap {
    width: 100%;
    background-color: #fff;
    background: linear-gradient(to right, #ffffff 82%,#be2f37 82%);
    display: flex;
    position: fixed;
    z-index: 2;
    top: 0;
    left: 0;
    padding: 15px 0 15px 2%;
    align-items: center;
    justify-content: space-between;
}
.home3-nav-button {
    display: block;
    width: 16%;
    background-color: transparent;
    border: none;
    color: #333;
    font-size: 1rem;
    font-weight: bold;
    text-decoration: none;
    text-align: center;
}
.home3-nav-reservations {
    width: 20%;
    height: 100%;
    display: block;
    text-align: center;
    align-items: center;
    justify-content: center;
    background-color: transparent;
    border: none;
    font-size: 1rem;
    font-weight: bold;
    text-decoration: none;
    color: #fff;
}
.home3-nav-reservations .icon-dateIcon-white {
    display: inline-block;
    width: 20px;
    height: 20px;
    margin-right: 5px;
}
.home3-nav-reservations span {
    color: #fff;
}
.home3-background-color {
    display: inline-block;
    background-color: #fff;
}
.home3-conetent1-background-color {
    background: linear-gradient(to top,#f3f3eb 50%,#ffffff 50%);
}
.home3-conetent1-wrap {
    display: flex;
    padding-bottom: 100px;
}
.home3-conetent1 {
    position: relative;
    padding: 0 20px;
    margin: 1px;
    height: 730px;
    width: 100%;
    background-position: center;
    background-size: cover;
    display: flex;
    align-items: flex-end;
}
.home3-conetent1-text {
    position: relative;
    bottom: -50px;
    color: #fff;
    font-size: 1rem;
    padding: 20px 8%;
    background-color: rgba(51, 51, 51, 0.95);
}
.home3-conetent1-italic {
    font-style: italic;
    font-size: 1.2rem;
    margin-bottom: 10px;
}
.home3-conetent1-title {
    font-size: 1.8rem;
    font-weight: 600;
    margin-bottom: 10px;
}
.home3-conetent2-wrap {
    position: relative;
    display: flex;
    background: linear-gradient(to right, #ffffff 30%,#f3f3eb 30%);
    align-items: center;
    justify-content: space-between;
    height: 560px;
    margin: 140px 10% 0;
}
.home3-conetent2-left {
    width: 38%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 15px;
    background-color: #fff;
}
.home3-conetent3-box {
    position: absolute;
    width: calc(100% - 46px);
    height: calc(100% - 46px);
    top: 23px;
    left: 23px;
    border: #f3f3eb 1px solid;
}
.home3-conetent2-left-box {
    width: 100%;
    padding: 20px 50px;
    border: #f3f3eb 1px solid;
}
.home3-conetent2-middle {
    position: relative;
    width: 16%;
    height: 630px;
    top: -70px;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}
.home3-conetent2-right {
    width: 42%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    padding: 0 20px;
}
.home3-conetent2-italic {
    width: 100%;
    color: #dfaeb0;
    font-style: italic;
    font-weight: 400;
    font-size: 1.2rem;
}
.home3-conetent2-title {
    font-size: 1.8rem;
    color: #333;
    font-weight: 600;
    margin: 10px 0 20px;
}
.home3-conetent2-text {
    font-size: 1.2rem;
    color: #333;
}
.home3-conetent2-right-img {
    width: 100%;
    height: 250px;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}
.home3-conetent3-wrap {
    position: relative;
    width: 100%;
    height: 350px;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    padding: 15px 0;
    margin: 70px 0;
}
.home3-conetent3-inner-wrap {
    width: calc(100% - 30px);
    height: 320px;
    position: relative;
    left: 0;
    top: 0;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 50px 10%;
    border: #fff 2px solid;
    margin: 0 15px;
}
.home3-conetent3-title {
    font-size: 2.5rem;
    color: #fff;
}
.home3-conetent3-button {
    position: relative;
    width: 350px;
    height: 50px;
    font-size: 1.5rem;
    text-transform: uppercase;
    background-color: transparent;
    margin-top: 50px;
    color: #fff;
    border: #fff 1px solid;
}
.home3-conetent3-button a {
    color: #fff;
}
.home3-conetent3-button::before {
    box-sizing: border-box;
    content: "";
    position: absolute;
    width: 350px;
    height: 50px;
    top: 3px;
    left: 3px;
    z-index: -1;
    border: #8f8f8f 1px solid;
}
.home3-conetent4-wrap {
    display: flex;
    align-items: center;
    padding: 70px 55px;
    border: #d2d2d2 4px solid;
    background-color: #f3f3eb;
}
.home3-conetent4-title {
    width: 35%;
    font-size: 2rem;
    color: #333;
    font-weight: 600;
}
.home3-conetent4-wrap i {
    width: 30px;
    height: 22px;
    transform: rotate(-90deg)
}
.home3-conetent4-wrap .home3-conetent2-text {
    width: 60%;
    margin: 0 20px;
}
/* home-02 */
.home2-header-top-wrap {
    background-color: #000;
    display: flex;
    position: fixed;
    z-index: 3;
    width: 100%;
    height: 32px;
    top: 0;
    left: 0;
    padding: 0 2%;
    align-items: center;
    justify-content: flex-end;
}
.home2-nav-list-wrap {
    position: fixed;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    /* top: 32px; */
    right: 0;
    z-index: 10;
}
.home2-nav-bt {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100px;
    height: 90px;
    right: 0;
    background-color: #333;
    text-align: center;
    justify-content: center;
    transition: background-color .2s 0s ease-in;
}
.home2-nav {
    position: relative;
    width: 100%;
    height: 0;
    left: 0;
    overflow: hidden;
    flex-flow: column;
    background-color: #be2f37;
    transition: height .3s 0s ease-in;
    top: 0;
    z-index: -1;
}
.home2-nav.is-active {
    height: 350px;
}
.home2-nav-button {
    display: block;
    background-color: transparent;
    color: #fff;
    border: none;
    font-size: 1.5rem;
    margin: 30px 60px;
    text-decoration: none;
}
.home2-nav-button i {
    display: inline-block;
    width: 18px;
    height: 20px;
    margin-right: 25px;
}
.home2-menu-text {
    color: #fff;
    font-size: 1rem;
}
.home2-hamburger {
    cursor: pointer;
    font: inherit;
    color: inherit;
    text-transform: none;
    background-color: transparent;
    border: 0;
    margin: 0;
    overflow: hidden;
}
.home2-hamburger-box {
    width: 50px;
    height: 45px;
    display: inline-block;
    position: relative;
}
.home2-hamburger-inner {
    display: block;
    top: 50%;
}
.home2-hamburger-inner::before {
    top: -12px;
    left: 0;
    transition: transform 0.125s cubic-bezier(0.6, 0.04, 0.98, 0.335), top 0.05s 0.125s linear, left 0.125s 0.175s ease-in;
}
.home2-hamburger-inner::after {
    top: 12px;
    right: 0;
    transition: transform 0.125s cubic-bezier(0.6, 0.04, 0.98, 0.335), top 0.05s 0.125s linear, right 0.125s 0.175s ease-in;
}
.home2-hamburger-inner, .home2-hamburger-inner::before, .home2-hamburger-inner::after {
    width: 50px;
    height: 3px;
    background-color: #FFF;
    position: absolute;
}
.home2-hamburger-inner::before, .home2-hamburger-inner::after {
    content: "";
    display: block;
}
.is-change {
    background-color: #be2f37;
}
.is-active .home2-hamburger-inner {
    transition-delay: 0s;
    transition-timing-function: ease-out;
    background-color: transparent !important;
}
.is-active .home2-hamburger-inner::before {
    left: -80px;
    top: -80px;
    transform: translate3d(80px, 80px, 0) rotate(45deg);
    transition: left 0.125s ease-out, top 0.05s 0.125s linear, transform 0.125s 0.125s cubic-bezier(0.075, 0.82, 0.165, 1);
}
.is-active .home2-hamburger-inner::after {
    right: -80px;
    top: -80px;
    transform: translate3d(-80px, 80px, 0) rotate(-45deg);
    transition: right 0.125s ease-out, top 0.05s 0.125s linear, transform 0.125s 0.125s cubic-bezier(0.075, 0.82, 0.165, 1);
}
.home2-inner-title-warpper {
    width: 70%;
    margin: 0 auto 50px;
    padding-top: 88px;
}
.home2-background-color {
    display: inline-block;
    background-color: #fff;
}
.home2-conetent-title-inner {
    font-size: 1.5rem;
    line-height: 1.8rem;
    color: #be2f37;
    text-align: center;
}
.home2-conetent1-wrap {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
}
.home2-conetent1-background-color {
    background: linear-gradient(to bottom, #f3f3eb 75%,#f3f3eb 75%,#ffffff 75%);
}
.home2-conetent1-left {
    position: relative;
    width: 40%;
    padding-bottom: 100px;
}
.home2-conetent1-right {
    position: relative;
    width: 40%;
    margin-top: 100px;
}
.home2-conetent1-left-img {
    display: block;
    width: 100%;
    height: 730px;
    margin: 0 auto;
    background-size: cover;
    background-position: center;
}
.home2-conetent1-right-img {
    display: block;
    width: 100%;
    height: 730px;
    margin: 0 auto;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}
.home2-conetent1-number {
    position: absolute;
    display: block;
    width: 90px;
    height: 87px;
    top: -65px;
    left: 30px;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
}
.home2-conetent1-left-text {
    position: absolute;
    bottom: 80px;
    right: -30px;
    width: 70%;
    padding: 20px 10px;
    font-size: 1.5rem;
    text-align: center;
    color: #fff;
    background-color: #be2f36ec;
}
.home2-conetent1-right-text {
    position: absolute;
    bottom: -20px;
    right: -30px;
    width: 70%;
    padding: 20px 10px;
    font-size: 1.5rem;
    text-align: center;
    color: #fff;
    background-color: #be2f36ec;
}
.home2-conetent2-wrap {
    display: flex;
    flex-wrap: wrap;
}
.home2-conetent2-inner {
    position: relative;
    width: 50%;
}
.home2-conetent2-left-img {
    display: block;
    width: 100%;
    height: 730px;
    margin: 0 auto;
    background-size: cover;
    background-position: center;
}
.home2-conetent2-text {
    width: 100%;
    position: absolute;
    top: 50%;
    transform: translate(-10%, -50%);
}
.home2-inner-text-italic {
    width: 100%;
    color: #8f8f8f;
    font-style: italic;
    font-weight: 400;
    font-size: 1.2rem;
    padding-bottom: .5rem;
    border-bottom: #333 1px solid;
}
.home2-inner-padding {
    padding-left: 150px;
}
.home2-inner-margin {
    margin-left: 150px;
}
.home2-inner-title {
    width: fit-content;
    color: #333;
    font-weight: 600;
    font-size: 2rem;
    padding-bottom: 2rem;
    border-top: #333 3px solid;
}
.home2-inner-text {
    width: 100%;
    color: #5a5a5a;
    font-weight: 400;
    font-size: 1.2rem;
}
.home2-button {
    position: relative;
    width: 350px;
    height: 50px;
    font-size: 1.5rem;
    text-transform: uppercase;
    margin-top: 50px;
    border: #333 1px solid;
    background-color: transparent;
    left: 50%;
    transform: translate(-50%, 0);
}
.home2-button a {
    color:  #333;
} 
.home2-button::before {
    box-sizing: border-box;
    content: "";
    position: absolute;
    width: 350px;
    height: 50px;
    top: 3px;
    left: 3px;
    z-index: -1;
    border: #8f8f8f 1px solid;
}
.home2-conetent3-wrap {
    position: relative;
    background-color: #f3f3eb;
    text-align: center;
    padding: 50px 20%;
    overflow: hidden;
}
.home2-conetent3-img {
    position: absolute;
    width: 35%;
    height: 100%;
    right: -10%;
    top: 50%;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    transform: translate(0, -50%);
    opacity: .5;
}
.home2-conetent3-text-italic {
    width: 100%;
    color: #8f8f8f;
    font-style: italic;
    font-weight: 400;
    font-size: 1.2rem;
    padding-bottom: .5rem;
}
.home2-conetent3-title {
    color: #333;
    font-weight: 600;
    font-size: 2rem;
    padding-bottom: 2rem;
}
.home2-conetent3-right-title {
    padding-bottom: 10px;
}
.home2-conetent4-wrap {
    display: flex;
    position: relative;
    background: linear-gradient(to right, #ffffff 10%,#f3f3eb 10%);
    padding: 35px 10%;
    align-items: center;
    justify-content: center;
}
.home2-conetent4-inner-wrap {
    display: flex;
    width: 100%;
    position: relative;
    padding: 35px 10%;
    top: 0;
    left: 0;
}
.home2-conetent4-background {
    position: absolute;
    width: 70%;
    height: 70%;
    background-color: #fff;
}
.home2-conetent4-background::after {
    box-sizing: border-box;
    content: "";
    position: absolute;
    width: calc(100% - 30px);
    height: calc(100% - 30px);
    border: #f3f3eb 1px solid;
    top: 15px;
    left: 15px; 
}
.home2-conetent4-left {
    width: 42%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0 50px;
}
.home2-conetent4-middle {
    position: relative;
    width: 20%;
    height: 630px;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}
.home2-conetent4-right {
    width: 42%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    padding: 0 0 0 30px;
}
.home2-conetent4-title-italic {
    position: absolute;
    top: 130px;
    right: -120%;
    width: 100%;
    color: #8f8f8f;
    font-style: italic;
    font-weight: 400;
    font-size: 6rem;
    letter-spacing: 4px;
}
.home2-conetent4-right-img {
    width: 100%;
    height: 250px;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}
.home2-conetent4-inner-padding {
    padding-left: 40px;
}
.home2-conetent4-inner-margin {
    margin-left: 40px;
}
.home2-conetent5-wrap {
    position: relative;
    width: 100%;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    margin: 70px 0;
}
.home2-conetent5-inner-wrap {
    width: 100%;
    height: 100%;
    position: relative;
    left: 0;
    top: 0;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 50px 10%;
}
.home2-conetent5-title {
    font-size: 2.5rem;
    color: #fff;
}
.home2-conetent5-button {
    position: relative;
    width: 350px;
    height: 50px;
    font-size: 1.5rem;
    text-transform: uppercase;
    background-color: transparent;
    margin-top: 50px;
    color: #fff;
    border: #fff 1px solid;
}
.home2-conetent5-button a {
    color: #fff;
}
.home2-conetent5-button::before {
    box-sizing: border-box;
    content: "";
    position: absolute;
    width: 350px;
    height: 50px;
    top: 3px;
    left: 3px;
    z-index: -1;
    border: #8f8f8f 1px solid;
}
.home2-conetent6-wrap {
    position: relative;
    width: 80%;
    height: 200px;
    margin: 0 auto;
}
.home2-conetent6-inner-wrap {
    display: flex;
    flex-wrap: wrap;
    position: absolute;
    width: 100%;
    height: 230px;
    bottom: -50px;
    left: 0;
    background-color: #f3f3eb;
    z-index: 2;
}
.home2-conetent6-inner-img {
    width: 50%;
    height: 100%;
    background-size: cover;
    background-position: bottom center;
    background-repeat: no-repeat;
}
.home2-conetent6-inner {
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0 50px;
}
.home2-conetent6-button {
    position: relative;
    width: 350px;
    height: 50px;
    font-size: 1.5rem;
    text-transform: uppercase;
    margin-top: 50px;
    border: #333 1px solid;
    background-color: transparent;
    left: 50%;
    transform: translate(-50%, 0);
}
.home2-conetent6-button::before {
    box-sizing: border-box;
    content: "";
    position: absolute;
    width: 350px;
    height: 50px;
    top: 3px;
    left: 3px;
    z-index: -1;
    border: #8f8f8f 1px solid;
}
/* home-03 */
.content-home-sub-title {
    font-family: 'Trade Gothic',"Microsoft JhengHei","微軟正黑體";
    letter-spacing: -2px;
    font-size: 2.2rem;
    text-transform: uppercase;
    line-height: 1;
    font-weight: 700;
    -webkit-font-smoothing: antialiased;
    padding-bottom: .5em;
    color: #333;
}

/* buildurl */
/* .home-inner-text-italic {
    color: #333;
    font-style: italic;
    font-weight: 400;
    font-size: 1.5rem;
    padding-bottom: .65rem;
} */
/* buildurl jp */
.home-inner-text-italic {
    color: #333;
    font-style: italic;
    font-weight: 400;
    font-size: 1.5rem;
    font-family: 'MS PGothic';
    padding-bottom: .65rem;
}
 
.home-block-style {
    display: none;
}
.home-huneeus-small {
    height: 420px;
    margin-top: 40px;
}
.home-feature-faust {
    height: 780px;
    margin-bottom: 2rem;
}
/* about us */
.aboutUs-left {
    width: 50%;
    min-width: 450px;
    margin-top: 20px;
}
.aboutUs-right {
    width: 65%;
    display: flex;
    padding-left: 3% ;
    margin-top: 20px;
    flex-direction: column;
    justify-content: flex-end;
}
.aboutUs-left-img {
    width: 90%;
    margin: 0 auto;
    height: 730px;
    background-repeat: no-repeat;
    background-size: cover;
    box-shadow: -20px -20px #dad8c7;
}
.aboutUs-rignt-img {
    width: 100%;
    height: 320px;
    background-repeat: no-repeat;
    background-size: cover;
    box-shadow: -18px -18px #dad8c7;
    margin-top: 20px;
}
.aboutUs-content-attr {
    width: 1160px;
    margin: 0 auto;
    display: flex;
}
.aboutUs-subTitle-red {
    font-family: 'Trade Gothic',"Microsoft JhengHei","微軟正黑體";
    letter-spacing: -1px;
    width: 100%;
    margin: 0 auto;
    font-size: 2rem;
    text-transform: uppercase;
    line-height: 1;
    font-weight: 700;
    -webkit-font-smoothing: antialiased;
    color: #be2f37;
}
.aboutUs-inner-text-italic {
    color: #7f7f7d;
    font-style: italic;
    font-weight: 400;
    font-size: 2rem;
    padding-bottom: .65rem;
    text-align: center;
}
.aboutUs-vedio {
    width: 100%;
    height: 500px;
    background-image: url(../aboutUsRuth.jpg);
    background-repeat: no-repeat;
    background-size: cover;
}
/* food & drink */
.food-drink-content-attr {
    position: relative;
    display: flex;
    align-items: center;
    padding-top: 35px;
}
.food-drink-content-attr-end {
    position: relative;
    display: flex;
    align-items: center;
    padding-top: 35px;
    justify-content: flex-end;
}
.food-drink-menuList-red {
    font-size: 1.8rem;
    letter-spacing: -1px;
    line-height: 1;
    font-weight: 500;
    color: #be2f37;
}
.food-drink-menuList-title {
    font-family: 'Trade Gothic',"Microsoft JhengHei","微軟正黑體";
    letter-spacing: -1px;
    font-size: 3rem;
    font-weight: 700;
    color: #333;
}
/* food list */
.food-backTop-prevpage {
    font-family: 'Trade Gothic',"Microsoft JhengHei","微軟正黑體";
    font-weight: 700;
    letter-spacing: -1px;
    cursor: pointer;
    margin: 50px auto 30px;
    display: block;
    width: 1160px;
    align-items: center;
    text-decoration: none;
    color: #be2f37;
    font-size: 1.7rem;
    text-transform: uppercase;
}
.food-backTop-prevpage span {
    position: relative;
    margin-right: 40px;
}
.food-backTop-prevpage span i {
    position: absolute;
    transition: left .5s;
    left: 1rem;
    top: 50%;
    width: 20px;
    height: 15px;
    transform: translate(-50%, -50%) rotate(90deg);
}
.food-backTop-prevpage:hover span i {
    left: 0.5rem; 
}
.food-white-frame {
    width: 100%;
    height: 100%;
    border: 1.3px solid #fff;
}
.food-SteakInfo-img {
    display: flex;
    color: #868686;
    width: 100%;
    height: 550px;
    background-size: cover;
    background-position: bottom;
    margin-bottom: 40px;
    padding: 10px;
}
.food-selection-left {
    cursor: pointer;
    width: 48%;
    height: 80px;
    display: flex;
    align-items: center;
    flex-direction: column;
    padding-right: 40px;
}
.food-selection-left span {
    font-family: 'Trade Gothic',"Microsoft JhengHei","微軟正黑體";
    position: relative;
    color: #be2f37;
    text-transform: uppercase;
    text-decoration: none;
    font-size: 2.5rem;
    font-weight: 700;
    letter-spacing: -1px;
}
.food-selection-left i {
    position: absolute;
    transition: right .5s;
    right: 0;
    top: 50%;
    width: 26px;
    height: 20px;
    transform: translate(-50%, -50%) rotate(90deg);
}
.food-selection-left:hover i {
    right: .5rem; 
}
.food-selection-right {
    cursor: pointer;
    width: 48%;
    height: 80px;
    display: flex;
    align-items: center;
    flex-direction: column;
    padding-left: 40px;
}
.food-selection-right span {
    font-family: 'Trade Gothic',"Microsoft JhengHei","微軟正黑體";
    position: relative;
    color: #be2f37;
    text-transform: uppercase;
    text-decoration: none;
    font-size: 2.5rem;
    font-weight: 700;
    letter-spacing: -1px;
    margin-right: 30px;
}
.food-selection-right i {
    position: absolute;
    transition: left .5s;
    left: 0;
    top: 50%;
    width: 26px;
    height: 20px;
    transform: translate(-50%, -50%) rotate(-90deg);
}
.food-selection-right:hover i {
    left: .5rem; 
}
.food-content-attr {
    display: flex;
    justify-content: space-between;
    padding: 50px 0;
}
.food-content-left {
    display: flex;
    width: 47.5%;
    flex-direction: column;
}
.food-content-right {
    display: flex;
    width: 47.5%;
    flex-direction: column;
}
.food-steak-photo {
    width: 100%;
    height: 650px;
    box-shadow: -20px -20px #dad8c7;
    overflow: hidden;
    display: flex;
    justify-content: center;
}
.food-steak-photo img{
    height: 100%;
    display: block;
}
.food-steak-list-item {
    margin: 0 0 15px;
}
.drink-list-item {
    margin: 15px 0;
    padding-right: 30px;
}
.food-steak-items-title {
    color: #be2f37;
    font-weight: 600;
    font-size: 2em;
    display: flex;
    align-items: center;
    text-transform: uppercase;
    text-decoration: none;
    margin-bottom: .3em;
}
.food-inner-site {
    padding: 20px 0;
    display: flex;
    flex-wrap: wrap;
}
 /* drinks */
.drinks-menu-text-italic {
    color: #8f8f8f;
    font-style: italic;
    font-weight: 400;
    font-size: 1.2rem;
}
.drink-wrap {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}
/* private events */
.private-block-style {
    display: none;
}
/* your privacy */
.privacy-content {
    background-color: #fff;
    padding: 20px;
}
.privacy-subContent {
    border: 1px solid #d2d2d2;
    padding: 5vw 10%;
    text-align: justify;
}

.privacy-inner-text-italic {
    font-family: Times;
    color: #7f7f7d;
    font-style: italic;
    font-weight: 300;
    font-size: 1.5em;
}
.privacy-list-item {
    display: flex;
    margin-bottom: 10px;
}
.privacy-star-icon {
    width: 18px;
    height: 18px;
    margin: 6px 10px;
}

/* promotions */
.promotions-hasBk {
    background-color: #e8e6da;
    padding: 1.5rem 3rem;
}
.promotions-text {
    text-align: center;
}
.promotions-site {
    display: flex;
    flex-wrap: wrap;
}
.promotions-block-style {
    display: none;
}
/* menber */
.member-search-wrap {
    width: 100%;
    display: flex;
    align-items: center;
}
.member-search-icon {
    width: 22px;
    height: 22px;
    margin-right: 10px;
}

/* promotions-inner */
.promotion-inner-backbtn {
    font-family: 'Trade Gothic',"Microsoft JhengHei","微軟正黑體";
    letter-spacing: -1px;
    width: 1160px;
    margin: 50px auto 30px;
}
.promotion-inner-backbtn:hover i {
    left: 0.5rem;
}
.promotion-inner-backbtn a {
    color: #be2f37;
    font-size: 1.7rem;
    font-weight: 700;
    text-decoration: none;
}
.promotion-inner-backbtn span {
    position: relative;
    margin-right: 2rem;
}
.promotion-inner-backbtn i {
    width: 20px;
    height: 15px;
    position: absolute;
    display: inline-block;
    left: 1rem;
    top: 50%;
    transition: left .5s;
    transform: translate(-50%, -50%) rotate(90deg);
}
.promotion-inner-imgblock {
    position: relative;
    width: 80%;
    margin: 35px auto;
}
.promotion-inner-imgblock img {
    width: 100%;
    height: 100%; 
}
.promotion-inner-imgblock:before {
    content: "";
    position: absolute;
    width: calc(100% - 30px);
    height: calc(100% - 30px);
    top: 50%;
    left: 50%;
    border: 1px solid #FFF;
    transform: translate(-50%, -50%);
}
.promotion-inner-menu {
    width: 50%;
    margin: 0 auto;
}
.promotion-inner-menu img {
    width: 100%;
    height: 100%; 
}
.promotion-inner-intro {
    width: 50%;
    padding: 2rem 1.5rem;
    font-size: 1.5rem;
    color: #333;
}
.promotion-inner-intro h3{
    font-size: 1.5rem;
    color: #333;
    font-size: 2.2rem;
    margin-bottom: 1rem;
    color: #333;
    background-size: cover;
    background-position: center;
}

/* footer */
.footer-icon {
    position: absolute;
    width: 35px;
    height: 35px;
    top: -60px;
    left: 50%;
    transform: translate(-50%, -50%);
}
.footer-socialIconWarp {
    width: 100%;
    display: flex;
    justify-content: center;
    font-size: 1.7em;
    margin: 10px 0 20px;
}
.footer-star-icon {
    width: 10px;
    height: 10px;
    opacity: .8;
    margin: 0 10px; 
}
.footer-social-icon {
    width: 30px;
    height: 30px;
    margin: 0 15px;
}
.footer-linkWep {
    width: 100%;
    font-family: 'Trade Gothic',"Microsoft JhengHei","微軟正黑體";
    display: flex;
    justify-content: center;
    text-transform: uppercase;
    font-size: 1rem;
    font-weight: bold;
    padding: 40px 0;
    flex-wrap: wrap;
}
.footer-linkBt {
    margin: 0 20px 20px;
    color: #f3f3ebad;
    text-decoration: none;
    transition: color .5s;
    display: block;
    text-align: center;
}
.footer-linkBt:hover {
    color: #f3f3eb;
}
.footer-inner-text {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: .9em;
    letter-spacing: 1px;
    text-align: center;
}
.footer-inner-text p {
    margin: 10px 5px;
    align-items: flex-start;
    /* align-self: center; */
}
.footer-copyright-text {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: .9em;
    letter-spacing: 1px;
    text-align: center;
    margin-top: 15px;
}
@media screen and (max-width: 1366px) {
    .aboutUs-content-attr {
        width: 90%;
    }
    .home3-nav-list-wrap {
        padding: 10px 0 10px 2%;
    }
    .home2-inner-title-warpper {
        width: 90%;
    }
    .promotion-inner-backbtn a {
        font-size: 1.3rem;
    }
    .promotion-inner-backbtn i {
        left: 1.3rem;
        width: 18px;
        height: 14px;
    }
    .promotion-inner-backbtn:hover i {
        left: 1rem;
    }
    .promotion-inner-backbtn {
        width: 90%;
        margin: 50px 0 0 5%;
    }
    .food-content-attr {
        width: 90%;
        margin: 0 auto;
    }
    .food-steak-list-item {
        margin: 15px 0;
    }
    .drink-list-item {
        margin: 15px 0;
    }
    .food-backTop-prevpage {
        width: 90%;
        font-size: 1.3rem;
        margin: 50px 0 0 5%;
    }
    .food-backTop-prevpage span i {
        left: 1.3rem;
        width: 18px;
        height: 14px;
    }
    .food-backTop-prevpage:hover span i {
        left: 1rem; 
    }
}
@media screen and (max-width: 1280px) {
    .food-selection-left span {
        font-size: 2rem;
    }
    .food-selection-right span {
        font-size: 2rem;
    }
    .home2-conetent3-img {
        width: 40%;
        height: 80%;
    }
    .aboutUs-left-img {
        box-shadow: -18px -18px #dad8c7;
        height: 700px;   
    }
    .promotion-inner-backbtn {
        margin: 50px 0 0 5%;
    }
    .promotion-inner-imgblock {
        width: 90%;
    }
    .home2-conetent4-wrap {
        background: linear-gradient(to right, #ffffff 5%,#f3f3eb 5%);
        padding: 35px 5%;
    }
    .home2-conetent4-inner-wrap {
        padding: 35px 5%; 
    }
    .home2-conetent4-background {
        width: 80%;
    }
    .aboutUs-subTitle-red {
        font-size: 1.8rem;
    }
}
@media screen and (max-width: 1200px) {
    /* .aboutUs-subTitle-red {
        font-size: 1.4rem;
    } */
}
@media screen and (max-width: 1023px) {
    .drink-wrap {
        display: flex;
        flex-direction: column;
    }
    .home3-nav-list-wrap {
        width: 100%;
        background-color: #333;
        background-image: none;
        top: 0;
        padding: 10px 3%;
    }
    .home3-nav-button {
        width: 100%;
        color: #fff;
        margin: 1.5rem 0;
        font-size: 2rem;
    }
    .home3-conetent3-wrap {
        height: auto;
    }
    .home3-conetent3-inner-wrap {
        height: calc(100% - 30px);
        padding: 50px 5%;
    }
    .home3-conetent3-button {
        width: 80%;
        font-size: 1.2rem;
    }
    .home3-conetent3-button::before {
        width: calc(100% + 2px);
    }
    .header3-logo-none {
        display: none;
    }
    .home3-conetent1-wrap {
        flex-wrap: wrap;
        padding-bottom: 0;
    }
    .home3-conetent1 {
        margin-bottom: 100px; 
    }
    .home3-conetent1-background-color {
        background: linear-gradient(to top,#fff 10%,#f3f3eb 10%,#f3f3eb 50%,#fff 50%);  
    }
    .home3-conetent1-text {
        padding: 30px 8%;
    }
    .home3-conetent1-title {
        font-size: 1.5rem;
    }
    .home3-conetent2-wrap {
        flex-wrap: wrap;
        background: linear-gradient(to bottom, #fff 10%,#f3f3eb 10%);
        height: auto;
        margin: 40px 0 0;
    }
    .home3-conetent2-middle {
        background-size: contain;
    }
    .home3-conetent2-left {
        width: 90%;
        margin: 0 auto;
        order: 2;
    }
    .home3-conetent2-middle {
        width: 100%;
        order: 1;
        top: 0;
    }
    .home3-conetent2-right {
        width: 90%;
        margin: 50px auto;
        padding: 0;
        order: 3;
    }
    .home3-conetent3-title {
        text-align: center;
        font-size: 1.8rem;
    }
    .home3-conetent4-wrap {
        flex-direction: column;
        padding: 50px 0;
    }
    .home3-conetent4-title {
        width: 90%;
        text-align: center;
        margin-bottom: 10px;
    }
    .home3-conetent4-wrap .home3-conetent2-text {
        width: 90%;
        text-align: center;
        margin-bottom: 20px;
    }
    .home3-conetent4-wrap i {
        transform: rotate(0deg);
    }
    /* .nav.is-active {
        height: 100%;
        transition: height 0.5s ease-in .25s;
    } */
    .home2-nav-bt-warp {
        display: flex;
        justify-content: center;
        flex-direction: column;
        margin-top: 50px;
    }
    .home3-nav-reservations {
        display: none;
    }
    .home2-nav-button i {
        width: 3vw;
        height: 4vw;
    }
    .home2-nav-bt {
        position: relative;
        width: 55px;
        height: 50px;
        right: 0;
        background-color: #333;
        text-align: center;
        justify-content: center;
        transition: background-color .2s 0s ease-in;
    }
    .is-change {
        background-color: #be2f37;
    }
    .is-fall {
        width: 100%;
        height: calc(100% + 25px);
    }
    .home2-nav {
        position: fixed;
        display: flex;
        align-items: center;
        width: 100%;
        height: 0;
        left: 0;
        overflow: hidden;
        flex-flow: column;
        background-color: #be2f37;
        transition: height .6s ease-in;
        /* top: 32px; */
        
    }
    .home2-nav.is-active {
        /* top: 32px; */
        height: 100%;
        transition: height .4s ease-in;
    }
    .home2-nav-button {
        font-size: 5vw;
        margin: 20px 0;
    }
    .home2-menu-text {
        font-size: .7rem;
    }
    .home2-hamburger {
        padding-bottom: 8px;
    }
    .home2-hamburger-box {
        width: 25px;
        height: 20px;
    }
    .home2-hamburger-inner {
        display: block;
        top: 50%;
        margin-top: 5px;
    }
    .home2-hamburger-inner::before {
        top: -8px;
    }
    .home2-hamburger-inner::after {
        top: 8px;
    }
    .home2-hamburger-inner, .home2-hamburger-inner::before, .home2-hamburger-inner::after {
        width: 25px;
        height: 3px;
        background-color: #FFF;
        position: absolute;
    }
    .home2-conetent1-left {
        position: relative;
        width: 100%;
    }
    .home2-conetent1-right {
        position: relative;
        width: 100%;
        padding: 0 0 100px;
        margin-top: 30px;
    }
    .home2-conetent1-number {
        left: 100px;
    }
    .home2-conetent1-right-img {
        width: 80%;
        height: 600px;
    }
    .home2-conetent1-left-img {
        width: 80%;
        height: 600px;
    }
    .home2-conetent2-left-img {
        width: 80%;
    }
    .home2-conetent1-left-text {
        bottom: 60px;
        left: 50%;
        transform: translate(-50%, 0);
        padding: 25px 10px;
    }
    .home2-conetent1-right-text {
        bottom: 60px;
        left: 50%;
        transform: translate(-50%, 0);
        padding: 25px 10px;
    }
    .home2-conetent2-inner {
        width: 100%;
        margin-bottom: 70px;
    }
    .home2-conetent2-text {
        width: 100%;
        position: relative;
        top: 0;
        transform: none;
    }
    .home2-inner-padding {
        padding-left: 0px;
    }
    .home2-inner-margin {
        margin-left: 0px;
    }
    .home2-conetent4-inner-padding {
        padding-left: 0px;
    }
    .home2-conetent4-inner-margin {
        margin-left: 0px;
    }
    .home2-inner-title {
        width: 100%;
    }
    .home2-conetent3-wrap {
        padding: 110px 5% 50px;
    }
    .home2-conetent3-img {
        width: 77%;
        height: 40%;
        right: -20%;
        top: 10px;
        transform: none;
    }
    .home2-conetent4-wrap {
        background-image: none;
        background-color: #f3f3eb;
        padding: 0;
        flex-direction: column;
        justify-content: flex-start;
    }
    .home2-conetent4-inner-wrap {
        display: flex;
        flex-direction: column;
        width: 100%;
        padding: 35px 5%;
        top: 0;
        left: 0;
    }
    .home2-conetent4-title-italic {
        position: absolute;
        width: fit-content;
        top: 100px;
        right: 0;
        font-size: 6rem;
    }
    .home2-conetent4-left {
        width: 100%;
        padding: 0;
        order: 2;
        margin-bottom: 25px;
    }
    .home2-conetent4-middle {
        width: 100%;
        height: 630px;
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
        order: 1;
        margin-bottom: 25px;
    }
    .home2-conetent4-right {
        width: 100%;
        padding: 0;
        order: 3;
        padding-bottom: 50px;
    }
    .home2-conetent4-background {
        width: 100%;
        height: calc(100% - 245px);
        background-color: #fff;
        margin: 210px 0 35px;
    }
    .home2-conetent4-background::after {
        box-sizing: border-box;
        content: "";
        position: absolute;
        width: calc(100% - 30px);
        height: calc(100% - 30px);
        border: #f3f3eb 1px solid;
        top: 15px;
        left: 15px; 
    }
    .home2-conetent5-wrap {
        margin: -35px 0 50px;
    }
    .home2-conetent5-title {
        font-size: 2rem;
    }
    .home2-conetent6-wrap {
        width: 90%;
    }
    .home2-conetent6-inner {
        padding: 0 25px;
    }
    .home2-conetent6-button {
        width: 80%;
        margin-top: 35px;
        font-size: 1.2rem;
    }
    .home2-conetent6-button::before {
        width: calc(100% + 2px);
    }
    .home-block-style {
        display: block;
        border-left: 1px solid #d2d2d2;
        margin: 0 5px;
    }
    .food-content-attr,.aboutUs-content-attr {
        flex-direction: column;
        padding: 0;
        margin: 0 auto;
    }
    .food-content-attr {
        width: 90%;
    }
    .food-content-left {
        width: 100%;
    }
    .food-content-right {
        width: 100%;
    }
    .food-steak-list-item {
        margin: 35px 0 0;
    }
    .drink-list-item {
        margin: 35px 0 0;
    }
    .food-margin-right {
        margin-top: 35px;
    }
    .food-selection-left i {
        width: 12px;
        height: 10px;
        right: .2rem;
        top: 45%;
    }
    .food-selection-right span {
        margin-right: 20px; 
        font-size: 1.5rem;
    }
    .food-selection-left span {
        font-size: 1.5rem;
    }
    .food-selection-right i {
        width: 12px;
        height: 10px;
        left: .2rem;
        top: 45%;
    }
    .aboutUs-left {
        width: 100%;
        margin-top: 0px;
    }
    .aboutUs-right {
        width: 100%;
        display: flex;
        padding-left: 0;
        margin-top: 0px;
    }
    .aboutUs-left-img {
        height: 730px;
        background-size: contain;
        background-position: center;
        box-shadow: none;
        margin-bottom: 35px;
    }
    .aboutUs-rignt-img {
        height: 450px;
        background-position: center;
        box-shadow: none;
        margin-bottom: 35px;
        margin-top: 10px;
    }
    .aboutUs-inner-text-italic {
        font-size: 1.5rem;
    }
    .aboutUs-subTitle-red {
        /* font-size: 1.5rem; */
    }
    .food-drink-menuList-red {
        font-size: 2rem;
    }
    .food-drink-menuList-title {
        font-size: 2.5rem;
    }
    .private-block-style {
        display: block;
        border-bottom: 1.5px solid #d2d2d2;
        margin: 20px 0;
        width: 100%;
    }
    .display-none {
        display: none;
    }
    .display-block {
        display: block;
    }
}
@media screen and (max-width: 925px) {
    .home2-conetent4-title-italic {
        top: 120px;
        font-size: 5rem;
    }
}
@media screen and (max-width: 768px) {
    .home-inner-text-italic {
        font-size: 1.4rem;
        padding-bottom: .3rem;
    }
    .home3-conetent1 {
        height: 550px;
        width: 80%;
        margin: 0 auto 100px;
    }
    .drinks-menu-text-italic {
        padding-bottom: 2px;
        font-size: 1rem;
    }
    .home2-conetent1-number {
        top: -40px;
        left: 70px;
    }
    .home3-conetent2-middle {
        height: 400px;
    }
    .home2-nav-button {
        margin: 4vw 0;
    }
    .home2-conetent1-left-text {
        font-size: 1.2rem;
    }
    .home2-conetent1-right-text {
        font-size: 1.2rem;
    }
    .home2-conetent1-left-img {
        height: 550px;
    }
    .home2-conetent1-right-img {
        height: 550px;
    }
    .home2-conetent3-wrap {
        padding: 90px 5% 35px;
    }
    .home2-conetent3-img {
        top: 10px;
        height: 35%;
    }
    .home2-conetent3-title {
        font-size: 1.5rem;
        padding-bottom: 1rem;
    }
    .home2-conetent2-left-img {
        height: 550px;
    }
    .home2-conetent2-inner {
        margin-bottom: 35px; 
    }
    .home2-button {
        width: 80%;
        font-size: 1.2rem;
    }
    .home2-button::before {
        box-sizing: border-box;
        width: calc(100% + 2px)
    }
    .home2-conetent4-background {
        height: calc(100% - 215px);
        margin: 180px 0 35px;
    }
    .home2-conetent4-title-italic {
        top: 115px;
        right: 0;
        font-size: 10vw;
        letter-spacing: 0;
    }
    .home2-conetent4-middle {
        height: 550px;
    }
    .home2-conetent5-wrap {
        margin: -25px 0 50px;
    }
    .home2-conetent5-title {
        text-align: center;
        font-size: 1.8rem;;
    }
    .home2-conetent5-button {
        margin-top: 35px;
    }
    .home2-conetent6-wrap {
        position: relative;
        width: 90%;
        height: 550px;
        margin: 0 auto;
    }
    .home2-conetent6-inner-wrap {
        height: auto;
    }
    .home2-conetent6-inner-img {
        width: 100%;
        height: 370px;
        background-size: cover;
        background-position: bottom center;
        background-repeat: no-repeat;
    }
    .home2-conetent6-inner {
        width: 100%;
        display: flex;
        padding: 20px;
    }
    .home-huneeus-small {
        height: 350px;
        background-size: cover;
    }
    .home-feature-faust {
        height: 680px;
        margin-bottom: 0rem;
    }
    .footer-inner-text {
        font-size: .7em;
    }
    .footer-copyright-text {
        font-size: .7em;
    }
    .food-steak-list-item {
        margin: 25px 0 0;
    }
    .drink-list-item {
        margin: 25px 0 0;
    }
    .aboutUs-left {
        min-width: 100%;
    }
    .aboutUs-left-img {
        height: 550px;
        margin-bottom: 25px;
    }
    .aboutUs-rignt-img {
        height: 350px;
        margin-bottom: 25px;
    }
    .food-backTop-prevpage {
        margin: 35px 0 0 5%;
        font-size: 1.2rem;
    }
    .food-backTop-prevpage span {
        margin-right: 30px;
    }
    .food-backTop-prevpage span i {
        width: 16px;
        height: 14px;
        left: 1rem;
        top: 50%;
    }
    .food-backTop-prevpage:hover span i {
        left: .5rem; 
    }
    .food-selection-left {
        padding-right: 0px;
        padding-top: 10px;
    }
    .food-selection-left span {
        font-size: 1.2rem;
    }
    .food-selection-right span {
        font-size: 1.2rem;
    }
    .food-selection-right {
        padding-left: 10px;
        padding-top: 10px;
    }
    
    .food-drink-menuList-red {
        font-size: 1.3rem;
    }
    .food-drink-menuList-title {
        font-size: 1.8rem;
    }
    .promotions-block-style {
        display: block;
        border-bottom: 1.5px solid #d2d2d2;
        margin: 15px auto;
        width: 90%;
    }
    .promotion-inner-backbtn {
        margin: 35px auto 0;
    }
    .promotion-inner-backbtn:hover i {
        left: .5rem;
    }
    .promotion-inner-backbtn a {
        font-size: 1.2rem;
    }
    .promotion-inner-backbtn i {
        width: 16px;
        height: 14px;
        left: 1rem;
        top: 50%;
    }
    .promotion-inner-imgblock {
        width: 100%;
    }
    .promotion-inner-imgblock:before {
        border: none;
    }
    .promotion-inner-menu img {
        width: 90%;
    }
    .promotion-inner-intro {
        background: none;
        width: 100%;
        color: #8f8f8f;
        padding: 1rem 15px;
        font-size: 1.2rem;
    }
    .promotion-inner-intro > h3 {
        font-size: 1.5rem;
        margin-bottom: .5rem;
    }
}
@media screen and (max-width: 510px) {
    .display-food-none {
        display: none;
    }
    .food-block-style {
        display: block;
        border-bottom: 1.5px solid #d2d2d2;
        margin: 15px auto;
        width: 90%;
    }
    .food-selection-right {
        width: 100%;
    }
    .food-selection-left {
        width: 100%;
        height: auto;
    }
}
@media screen and (max-width: 414px) {
    .food-selection-left span {
        font-size: 1rem;
    }
    .food-selection-right span {
        font-size: 1rem;
    }
    .drinks-menu-text-italic {
        padding-bottom: 0px;
        font-size: .8rem;
    }
    .promotion-inner-backbtn a {
        font-size: 1rem;
    }
    .promotion-inner-backbtn i {
        width: 13px;
        height: 10px;
        left: 1rem;
        top: 50%;
    }
    .promotion-inner-backbtn:hover i {
        left: .5rem;
    }
    .aboutUs-content-attr {
        width: 100%;
    }
    .aboutUs-inner-text-italic {
        font-size: 1.2rem;
    }
    .footer-icon {
        width: 30px;
        height: 30px;
        top: -55px;
    }
    .food-selection-left {
        padding-top: 0px;
    }
    .food-selection-right {
        padding-top: 0px;
    }
    .food-selection-left i {
        width: 12px;
        height: 10px;
        top: 45%;
    }
    .food-selection-right i {
        width: 12px;
        height: 10px;
        left: .2rem;
        top: 45%;
    }
    .food-drink-content-attr-end {
        padding-top: 0;
    }
    .food-drink-content-attr {
        padding-top: 0;
    }
    .food-backTop-prevpage {
        font-size: 1rem;
    }
    .food-backTop-prevpage span i {
        width: 13px;
        height: 10px;
        left: 1rem;
        top: 50%;
    }
    .home2-nav-bt {
        position: relative;
        width: 55px;
        height: 50px;
        right: 0;
        background-color: #333;
        text-align: center;
        justify-content: center;
        transition: background-color .2s 0s ease-in;
    }
    .home3-nav-button {
        font-size: 1.2rem;
        margin: 1.2rem 0;
    }
    .home3-conetent2-left {
        padding: 10px;
    }
    .home3-conetent2-left-box {
        padding: 20px;
    }
    .home3-conetent3-title {
        font-size: 1.5rem;
    }
    .home3-conetent2-title {
        font-size: 1.5rem;
        margin: 10px 0;
    }
    .home3-conetent3-inner-wrap {
        padding: 35px 5%;
    }
    .home3-conetent4-wrap {
        padding: 35px 0;
    }
    .home3-conetent1 {
        height: 500px;
    }
    .home2-inner-title-warpper {
        padding-top: 35px;
    }
    .home2-conetent1-left-img {
        height: 350px;
    }
    .home2-conetent1-right-img {
        height: 350px;
    }
    .home2-conetent3-wrap {
        padding: 60px 5% 35px;
    }
    .home2-conetent3-img {
        top: 10px;
        right: -25%;
        height: 30%;
    }
    .home2-conetent2-left-img {
        height: 350px;
    }
    .home2-conetent4-middle {
        height: 350px;
    }
    .home2-conetent4-background::after {
        width: calc(100% - 20px);
        height: calc(100% - 20px);
        top: 10px;
        left: 10px; 
    }
    .home2-conetent4-background {
        height: calc(100% - 165px);
        margin: 130px 0 35px;
    }
    .home2-conetent4-title-italic {
        top: 65px;
    }
    .home2-conetent5-title {
        font-size: 1.5rem;;
    }
    .home2-conetent5-button {
        margin-top: 25px;
        width: 80%;
    }
    .home2-conetent5-button::before {
        width: calc(100% + 2px);
    }
    .home-huneeus-small {
        height: 200px;
        margin-top: 20px;
    }
    .home-feature-faust {
        height: 420px;
    }
    .footer-inner-text {
        font-size: .5em;
        letter-spacing: 0;
    }
    .footer-linkWep {
        padding: 30px 10px 20px;
        margin: 0 auto;
    }
    .footer-inner-text p {
        margin: 10px 0;
        align-self: center;
    }
    .footer-copyright-text {
        font-size: .5em;
    }
    .footer-star-icon {
        margin: 0 5px; 
    }
    .food-steak-list-item {
        margin: 15px 0 0;
    }
    .drink-list-item {
        margin: 15px 0 0;
    }
    .content-home-sub-title {
        font-size: 1.5rem; 
    }
    .home-inner-text-italic {
        font-size: 1.2rem;
        padding-bottom: .3rem;
    }
    .aboutUs-left-img {
        width: 100%;
        height: 400px;
    }
    .food-drink-menuList-red {
        font-size: 1rem;
    }
    .food-drink-menuList-title {
        font-size: 1.5rem;
    }
    .home2-conetent-title-inner {
        font-size: 1rem;
        line-height: 1.3rem;
    }
    .promotion-inner-intro {
        width: 75%;
        padding: .5rem 0;
        font-size: 1rem;
    }
    .promotion-inner-intro > h3 {
        font-size: 1.2rem;
        margin-bottom: .4rem;
    }
    .aboutUs-subTitle-red {
        width: 90%;
        font-size: 1.2rem;
    }
}

@media screen and (max-width: 375px) {
    .home2-conetent6-button {
        font-size: 1rem;
        margin-top: 10px;
    }
    .footer-inner-text {
        font-size: .7em;
    }
    .footer-copyright-text {
        font-size: .7em;
    }
    .footer-linkBt {
        margin: 0 10px 30px;
        width: 40%;
    }
    .aboutUs-subTitle-red {
        /* font-size: .9rem; */
    }
    .home3-conetent3-button {
        width: 90%;
        font-size: 1rem;
    }
}
